import React, { useState, useEffect } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logoLato from './assets/logo_arimo_v3.png'

const navigation = [
  { name: 'How it works', href: '/#how-it-works' },
  { name: 'Pricing', href: '/#pricing' },
  { name: 'FAQ', href: '/#faq' },
  { name: 'Blog', href: '/blog' },
];

const TermsOfService = () => {
  const [user, setUser] = useState<User | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const SCROLL_DELTA = 10; // Adjust sensitivity here
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    function handleScroll() {
      const currentScrollY = window.pageYOffset;

      // Toggle hideHeader logic (already in your code)
      if (currentScrollY - lastScrollY > SCROLL_DELTA) {
        setHideHeader(true);
      } else if (lastScrollY - currentScrollY > 0) {
        setHideHeader(false);
      }
      lastScrollY = currentScrollY;

      // NEW: Toggle the navbar background
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Update document title
  useEffect(() => {
    document.title = 'Terms of Service | HinterviewGPT';
  }, []);

  // Listen for auth changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser ? authUser : null);
    });
    return () => unsubscribe();
  }, []);

  // Hide header on scroll (bento-style)
  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY - lastScrollY > 10) {
        setHideHeader(true);
      } else if (lastScrollY - currentScrollY > 0) {
        setHideHeader(false);
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* HEADER */}
      <header
        className={`
                        sticky top-0 z-50 transition-all duration-300
                        ${hasScrolled ? 'bg-white/50 backdrop-blur-sm' : 'bg-gray-50'}
                        
                      `}
      >
        <nav aria-label="Global" className="mx-auto
      flex
      max-w-7xl
      items-center
      justify-between
      px-4
      py-3  
      lg:px-8">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src={logoLato}
                className="h-8 w-auto"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm/6 font-semibold text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
            {user ? (
              <a
                href="/activity"
                className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
              >
                Dashboard
              </a>
            ) : (
              <>
                <a
                  href="/auth?type=login"
                  className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
                >
                  Log In
                </a>
                <a
                  href="/auth?type=register"
                  className="ml-4 rounded-md border border-indigo-600 px-3 py-1.5 text-md font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Register
                </a>
              </>
            )}
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-10" />
          <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src={logoLato}
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  {user ? (
                    <a
                      href="/activity"
                      className="rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500 transition duration-200"
                    >
                      Dashboard
                    </a>
                  ) : (
                    <>
                      <a
                        href="/auth?type=login"
                        className="block w-full rounded-md bg-indigo-600 px-4 py-2 text-base font-semibold text-white hover:bg-indigo-500 transition duration-200"
                      >
                        Log In
                      </a>
                      <a
                        href="/auth?type=register"
                        className="mt-3 block w-full rounded-md border border-indigo-600 px-4 py-2 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white transition duration-200"
                      >
                        Register
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>

      </header>

      {/* MAIN CONTENT */}
      <main className="flex-grow py-8 sm:py-12">
        <article className="mx-auto max-w-5xl px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-6">Terms of Service</h1>
          <div className="prose max-w-none text-gray-800">
            <section>
              <h2>Introduction</h2>
              <p>
                Welcome to HinterviewGPT. By using our services, you agree to these Terms of Service. Please read them carefully.
              </p>
            </section>

            <section>
              <h2>Use of Services</h2>
              <p>
                HinterviewGPT grants you a limited, non-exclusive, non-transferable, and revocable license to use our services in accordance with these Terms. You agree not to use our services for any unlawful or prohibited activities.
              </p>
            </section>

            <section>
              <h2>User Content</h2>
              <p>
                By submitting content through HinterviewGPT, including questions, solutions, and other data, you grant us a license to use, modify, and display this content solely for the purpose of providing our services to you. You retain ownership of all your content.
              </p>
              <p>
                You are responsible for ensuring that your content does not violate any laws or infringe on the rights of third parties. We reserve the right to remove any content that violates these terms.
              </p>
            </section>

            <section>
              <h2>Account Security</h2>
              <p>
                You are responsible for maintaining the security of your account and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
              </p>
            </section>

            <section>
              <h2>Limitation of Liability</h2>
              <p>
                To the fullest extent permitted by law, HinterviewGPT shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our services.
              </p>
            </section>

            <section>
              <h2>Termination</h2>
              <p>
                We reserve the right to suspend or terminate your access to our services at any time, with or without notice, for conduct that we believe violates these Terms of Service or is harmful to other users, to us, or to third parties, or for any other reason.
              </p>
            </section>

            <section>
              <h2>Governing Law</h2>
              <p>
                These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction in which HinterviewGPT operates, without regard to its conflict of law principles.
              </p>
            </section>

            <section>
              <h2>Changes to These Terms</h2>
              <p>
                We may update these Terms of Service from time to time. If we make material changes, we will provide you with notice through our services or by other means, giving you the opportunity to review the changes before they become effective. By continuing to use our services after the changes come into effect, you agree to be bound by the revised terms.
              </p>
            </section>

            <section>
              <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns about these Terms of Service, please contact us at support@hinterviewgpt.com.
              </p>
            </section>
          </div>
        </article>
      </main>

      {/* FOOTER */}
      <footer className="bg-white border-t border-gray-300 mt-auto text-gray-600">
        <div className="mx-auto max-w-7xl py-6 px-6 lg:px-8 flex flex-col sm:flex-row items-center justify-between text-sm">
          <p className="text-center sm:text-left">
            © 2024 HinterviewGPT. All rights reserved.
          </p>
          <div className="mt-4 sm:mt-0">
            <a href="/privacy" className="hover:text-indigo-500 transition ml-4">
              Privacy Policy
            </a>
            <a href="/tos" className="hover:text-indigo-500 transition ml-4">
              Terms of Service
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TermsOfService;
