import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild
} from '@headlessui/react';
import { Dialog as TWDialog, DialogPanel as TWDialogPanel, DialogTitle as TWDialogTitle, Transition as TWTransition, TransitionChild as TWTransitionChild } from '@headlessui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { db } from './config/firebase';
import { doc, getDoc, updateDoc, setDoc, getDocs, collection, query, where, addDoc, onSnapshot } from 'firebase/firestore';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { IconEdit, IconEye, IconPlus, IconX } from '@tabler/icons-react';
import Navbar from './navbar';
import saveActivity from './utils/saveActivity';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Menu, MenuButton, MenuItem as HeadlessMenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import { IconTag } from '@tabler/icons-react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import {
    Chip,

} from '@mui/material';

type QuestionType = {
    qRef: string;
    title: string;
    tags: string[];
    difficulty: string;
    answerType: string;
};

const AddManual = () => {

    const [selectedDifficulty, setSelectedDifficulty] = React.useState('');
    const [questionTitle, setQuestionTitle] = React.useState('');
    const [questionTags, setQuestionTags] = React.useState('');
    const [tags, setTags] = React.useState<string[]>([]);
    const [questionBody, setQuestionBody] = React.useState('');
    const [selectedAnswerType, setSelectedAnswerType] = React.useState('');
    const [user, setUser] = useState<User | null>(null); // User state
    const [isFormValid, setIsFormValid] = React.useState(false);
    const [tagErrorMessage, setTagErrorMessage] = React.useState(''); // Tag error message
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [numQuestions, setNumQuestions] = React.useState(1);
    const [maxQuestionsDialogOpen, setMaxQuestionsDialogOpen] = React.useState(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorDialogTitle, setErrorDialogTitle] = useState('');

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const displayErrorSnackbar = (message: string) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleErrorSnackbarClose = () => {
        setErrorSnackbarOpen(false);
    };


    useEffect(() => {
        // Update form validation state whenever relevant state changes
        setIsFormValid(!!questionTitle && !!selectedAnswerType && !!questionBody && tags.length <= MAX_TAGS);
    }, [questionTitle, selectedAnswerType, questionBody, tags]);

    const MAX_TAGS = 5; // Maximum number of tags
    const MAX_QUESTION_BODY_LENGTH = 10000;

    const navigate = useNavigate();

    useEffect(() => {
        const checkAccess = async () => {
            if (!user) return;

            // 1. Check subscription status
            const subscriptionsRef = collection(db, 'customers', user.uid, 'subscriptions');
            const activeSubscriptions = query(subscriptionsRef, where('status', 'in', ['active', 'trialing']));
            const subscriptionSnap = await getDocs(activeSubscriptions);

            // If an active or trialing subscription exists
            if (!subscriptionSnap.empty) {
                setHasAccess(true);
                return;
            }

            // 2. Check for free trial eligibility (7 days after sign-up)
            const signUpDate = new Date(user.metadata.creationTime!); // Firebase stores sign-up date
            const currentDate = new Date();
            const timeDiff = currentDate.getTime() - signUpDate.getTime();
            const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

            // If within 7-day trial period
            if (daysSinceSignUp < 7) {
                setTrialRemaining(7 - daysSinceSignUp);
                setHasAccess(true);
                return;
            }

            // If no active subscription and free trial expired, redirect to the no-access page
            navigate('/no-access');
        };

        if (user) {
            checkAccess();
        }
    }, [user, navigate]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                // User is signed in.
                setUser(authUser);
            } else {
                // No user is signed in.
                setUser(null);
                navigate('/auth');
            }
        });

        return () => unsubscribe(); // Unsubscribe on unmount
    }, []);

    useEffect(() => {

        document.title = 'Manually Add Question | HinterviewGPT';

    }, []);

    const showErrorDialog = (title: string, message: string) => {
        setErrorDialogTitle(title);
        setErrorMessage(message);
        setErrorDialogOpen(true);
    };

    const getLeetCodeQuestions = async () => {
        if (!user) {
            return;
        }

        if (user) {
            try {
                const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
                const userQuestionsListDoc = await getDoc(userQuestionsListRef);

                if (userQuestionsListDoc.exists()) {
                    const userQuestionsList = userQuestionsListDoc.data().questions || [];
                    const queriedData: QuestionType[] = userQuestionsList.map((question: QuestionType) => {
                        const { title, difficulty, qRef, tags, answerType } = question;
                        return {
                            qRef,
                            title,
                            tags,
                            difficulty,
                            answerType,
                        };
                    });
                    setNumQuestions(queriedData.length);
                }
            } catch (err) {
                console.error('Error getting documents: ', err);
            }
        }
    };

    useEffect(() => {
        if (user) {
            getLeetCodeQuestions();
        }
    }, [user]);

    const handleSubmit = async () => {

        const maxQuestions = 200;

        if (numQuestions >= maxQuestions) {
            showErrorDialog('Maximum Questions Exceeded', `You have reached the maximum number of questions you can add (${maxQuestions}).  Try deleting some questions in Question List to make space for new questions.`);

            setErrorDialogOpen(true);
            return;
        }
        const errors: string[] = [];

        if (!questionTitle.trim()) {
            errors.push("Title is required.");
        }
        if (!selectedAnswerType) {
            errors.push("Answer Type is required.");
        }
        if (!questionBody.trim()) {
            errors.push("Body is required.");
        }
        if (tags.length > MAX_TAGS) {
            errors.push(`A maximum of ${MAX_TAGS} tags is allowed.`);
        }
        if (questionBody.length > MAX_QUESTION_BODY_LENGTH) {
            errors.push(`Question body is too large. Maximum allowed length is ${MAX_QUESTION_BODY_LENGTH} characters.`);
        }

        // If there are any errors, display them and exit.
        if (errors.length > 0) {
            console.error(errors.join(" "));
            displayErrorSnackbar(errors.join(" "));
            return;
        }
        try {
            if (!user || !user.uid) {
                console.error('User not authenticated.');
                return;
            }

            if (!isFormValid) {
                console.error('Please fill in all required fields.');
                displayErrorSnackbar('Please fill in all required fields.');
                return;
            }

            if (questionBody.length > MAX_QUESTION_BODY_LENGTH) {
                console.error('Question body is too large.');
                displayErrorSnackbar(`Question body is too large. Maximum allowed length is ${MAX_QUESTION_BODY_LENGTH} characters.`);
                return;
            }

            const userQuestionsCollectionRef = collection(db, 'userQuestions');

            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const currentDate = new Date();
            const options: Intl.DateTimeFormatOptions = { timeZone: userTimeZone, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            const formattedDate = currentDate.toLocaleDateString(undefined, options);

            const newQuestionRef = await addDoc(userQuestionsCollectionRef, {
                title: questionTitle,
                tags: tags,
                difficulty: selectedDifficulty,
                answerType: selectedAnswerType,
                body: questionBody,
                user: user?.uid,
                method: 'manual',
                createdAt: formattedDate
            });

            const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
            const userQuestionsListDoc = await getDoc(userQuestionsListRef);

            if (userQuestionsListDoc.exists()) {
                await updateDoc(userQuestionsListRef, {
                    questions: [
                        ...userQuestionsListDoc.data().questions,
                        {
                            title: questionTitle,
                            tags: tags,
                            difficulty: selectedDifficulty,
                            answerType: selectedAnswerType,
                            qRef: newQuestionRef.id
                        },
                    ],
                });
            } else {
                await setDoc(userQuestionsListRef, {
                    questions: [{
                        title: questionTitle,
                        tags: tags,
                        difficulty: selectedDifficulty,
                        answerType: selectedAnswerType,
                        qRef: newQuestionRef.id
                    }],
                });
            }

            const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${newQuestionRef.id}`);

            const userAttemptData = {
                userID: user.uid,
                questionID: newQuestionRef.id,
                code: []
            };
            try {
                await setDoc(userAttemptDocRef, userAttemptData);
            } catch (error) {
                console.error('Error saving code:', error);
            }

            setQuestionTitle('');
            setQuestionTags('');
            setSelectedDifficulty('');
            setSelectedAnswerType('');
            setQuestionBody('');

            navigate(`/question/${newQuestionRef.id}`);
            await saveActivity(user.uid, "add");

        } catch (error) {
            console.error('Error adding document: ', error);
            displayErrorSnackbar('Error adding question. Please try again later.');
        }
    };

    const handleCloseModal = () => {
        setTagErrorMessage('');
    };

    const handleDifficultyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDifficulty(event.target.value);
    };

    const handleAnswerTypeChange = (answerType: string) => {
        setSelectedAnswerType(answerType);
    };

    const handleQuestionTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestionTitle(event.target.value);
    };

    const handleQuestionTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestionTags(event.target.value);
    };

    const handleQuestionBodyChange = (value: string) => {
        setQuestionBody(value);
    };

    const handleTagAdd = () => {
        if (questionTags.trim() !== '') {
            if (tags.length < MAX_TAGS) {
                setTags([...tags, questionTags.trim()]);
                setQuestionTags('');
                setTagErrorMessage('');
            } else {
                setTagErrorMessage(`Maximum ${MAX_TAGS} tags allowed.`);
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleTagAdd();
        }
    };

    const handleTagRemove = (tagToRemoveIdx: number) => {
        setTags(tags.filter((tag, index) => index !== tagToRemoveIdx));
    };

    const RenderHTMLContent: React.FC<{ content: string }> = ({ content }) => {
        return <div className="custom-html-content" dangerouslySetInnerHTML={{ __html: content }} />;
    };

    return (
        <>
            <div className="flex flex-col h-screen overflow-hidden mx-auto">
                <Navbar currentNav="Add Question" />
                <header className="bg-white">
                    <div className="mx-auto max-w-7xl py-4 flex items-center bg-white">
                        <span
                            className="text-sm font-semibold leading-6 text-gray-600 cursor-pointer"
                            onClick={() => navigate('/add')}
                        >
                            Add Question
                        </span>
                        <svg
                            className="w-4 h-4 mx-2 font-semibold fill-current text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fillRule="evenodd"
                                d="M7.293 15.293a1 1 0 0 1-1.414-1.414L10.586 10 5.293 4.707a1 1 0 1 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0z"
                            />
                        </svg>
                        <h1 className="text-sm font-semibold leading-6 text-gray-600">
                            Manually Add  Question
                        </h1>
                    </div>
                </header>

                <div className="flex-grow bg-white">
                    <div className="mx-auto max-w-7xl py-0">

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                            {/* LEFT CARD: QUESTION DETAILS */}
                            <div className="flex flex-col bg-gray-100 border rounded-lg h-[630px] overflow-hidden w-full p-2">
                            <div
                                    className="flex justify-between items-center  mb-2"
                                >
                                    <div className="flex items-center space-x-2">
                                        <h2 className="text-lg font-semibold text-gray-600 flex items-center ml-1 space-x-2">
                                            {/* <SparklesIcon className="h-5 w-5 text-gray-500" /> */}
                                            <ModeEditOutlineOutlinedIcon className="h-7 w-7 text-gray-400" />
                                            <span>Enter Question Details</span>
                                        </h2>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        
                                        <button
                                            onClick={handleSubmit}
                                            className="invisible bg-green-600 text-white px-3 py-2 mr-1 rounded-md text-sm font-medium flex items-center disabled:bg-green-400 disabled:cursor-not-allowed"
                                        >
                                            Practice
                                        </button>
                                    </div>

                                </div>
                                <div className="px-4 py-5 sm:p-6 overflow-auto bg-white rounded-lg flex-1">
                                    <div className="grid grid-cols-1 gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-4 mr-2">
                                            <label
                                                htmlFor="title"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Title
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                    onChange={handleQuestionTitleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label
                                                htmlFor="answerType"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Answer Type
                                            </label>
                                            <Menu as="div" className="relative inline-block w-full mt-1">
                                                <MenuButton className="inline-flex w-full justify-between items-center rounded-md px-3 py-2 bg-white text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none">
                                                    {selectedAnswerType || 'None'}
                                                    <ChevronDownIcon
                                                        aria-hidden="true"
                                                        className="h-5 w-5 text-gray-500"
                                                    />
                                                </MenuButton>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <MenuItems className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1">
                                                            {['Code', 'Text'].map((type) => (
                                                                <HeadlessMenuItem key={type}>
                                                                    {({ active }) => (
                                                                        <button
                                                                            onClick={() => handleAnswerTypeChange(type)}
                                                                            className={`${active
                                                                                ? 'bg-gray-100 text-gray-900'
                                                                                : 'text-gray-700'
                                                                                } block px-4 py-2 text-sm w-full text-left flex items-center justify-between`}
                                                                        >
                                                                            {type}
                                                                            {type === selectedAnswerType && (
                                                                                <CheckIcon
                                                                                    aria-hidden="true"
                                                                                    className="h-5 w-5"
                                                                                />
                                                                            )}
                                                                        </button>
                                                                    )}
                                                                </HeadlessMenuItem>
                                                            ))}
                                                        </div>
                                                    </MenuItems>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>

                                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-4">
                                        <div className="sm:col-span-4">
                                            <label
                                                htmlFor="website"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Tags
                                            </label>
                                            <div className="flex items-center mt-1">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-md relative">
                                                    <input
                                                        type="text"
                                                        name="website"
                                                        id="website"
                                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-2 pr-8 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                                        value={questionTags}
                                                        onChange={handleQuestionTagsChange}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    <a
                                                        href="#"
                                                        onClick={handleTagAdd}
                                                        className="absolute inset-y-0 right-0 flex items-center pr-2"
                                                    >
                                                        <IconPlus fontSize="large" color="blue" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-2 min-h-[42px]">
                                        {tags.map((tag, idx) => (
                                            <Chip
                                                key={tag}
                                                label={tag}
                                                onDelete={() => handleTagRemove(idx)}
                                                style={{ margin: '5px' }}
                                            />
                                        ))}
                                    </div>


                                    <div className="mt-2">
                                        <div className="sm:col-span-4">
                                            <label
                                                htmlFor="website"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Body
                                            </label>
                                            <div className="mt-1">
                                                <ReactQuill
                                                    value={questionBody}
                                                    onChange={handleQuestionBodyChange}
                                                    theme="snow"
                                                    modules={{
                                                        toolbar: [
                                                            [{ header: [1, 2, false] }],
                                                            [
                                                                'bold',
                                                                'italic',
                                                                'underline',
                                                                'strike',
                                                                'blockquote',
                                                                'code-block'
                                                            ],
                                                            [{ list: 'ordered' }, { list: 'bullet' }],
                                                            [
                                                                { indent: '-1' },
                                                                { indent: '+1' },
                                                                { align: [] }
                                                            ],
                                                            [{ formula: true }]
                                                        ]
                                                    }}
                                                    style={{
                                                        marginBottom: '10px',
                                                        height: '250px',
                                                        minHeight: '150px',
                                                    }}
                                                    className="custom-quill-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* RIGHT CARD: PREVIEW */}
                            <div className="flex flex-col bg-gray-100 border rounded-lg h-[630px] overflow-hidden w-full p-2">
                                <div
                                    className="flex justify-between items-center  mb-2"
                                >
                                    <div className="flex items-center space-x-2">
                                        <h2 className="text-lg font-semibold text-gray-600 flex items-center ml-1 space-x-2">
                                            {/* <SparklesIcon className="h-5 w-5 text-gray-500" /> */}
                                            <VisibilityOutlinedIcon className="h-4 w-4 text-gray-400" />
                                            <span>Preview</span>
                                        </h2>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <div className="flex items-center pr-2 min-w-[150px]">
                                            {selectedAnswerType ? (
                                                <>
                                                    <span className="text-sm font-semibold leading-6 text-gray-600 mr-1">
                                                        Answer Type:
                                                    </span>
                                                    <span className="rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                                        {selectedAnswerType}
                                                    </span>
                                                </>
                                            ) : (
                                                // Even if no answer type is selected, this invisible placeholder reserves the space.
                                                <div className="invisible">Placeholder</div>
                                            )}
                                        </div>
                                        <button
                                            onClick={handleSubmit}
                                            className="bg-green-600 text-white px-3 py-2 mr-1 rounded-md text-sm font-medium flex items-center disabled:bg-green-400 disabled:cursor-not-allowed"
                                        >
                                            Practice
                                        </button>
                                    </div>

                                </div>
                                <div className="px-4 py-5 sm:p-6 overflow-auto bg-white rounded-lg flex-1"
                                >
                                    <div className="divide-gray-200">
                                        <div className="flex items-center justify-between py-2 mb-2">
                                            <div className="flex items-center">
                                                <h3 className="text-2xl font-bold leading-6 text-gray-900 mb-4">
                                                    {questionTitle}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            {tags.map((tag, index) => (
                                                <span
                                                    key={index}
                                                    className="inline-flex items-center rounded-xl bg-gray-200 px-2 py-1 text-xs font-medium mr-1 text-gray-600"
                                                >
                                                    <IconTag className="w-3.5 h-3.5 mr-1" />
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                        <RenderHTMLContent content={questionBody} />
                                        <Snackbar
                                            open={snackbarOpen}
                                            autoHideDuration={6000}
                                            onClose={handleSnackbarClose}
                                        >
                                            <MuiAlert
                                                elevation={6}
                                                variant="filled"
                                                severity="error"
                                                onClose={handleSnackbarClose}
                                            >
                                                {snackbarMessage}
                                            </MuiAlert>
                                        </Snackbar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="bg-white border-t border-gray-200 mt-auto">
                    <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                        <p className="text-gray-500">© 2024 HinterviewGPT. All rights reserved.</p>
                        <div>
                            <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                                Privacy Policy
                            </a>
                            <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                                Terms of Service
                            </a>
                        </div>
                    </div>
                </footer>

                {/* Tailwind/Headless UI Dialog for Tag Error Message */}
                <Transition.Root show={!!tagErrorMessage} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div>
                                            <Dialog.Title className="text-lg font-medium text-gray-900">
                                                Error
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-red-600">{tagErrorMessage}</p>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={handleCloseModal}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity="error"
                        onClose={handleSnackbarClose}
                    >
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>


                <Transition show={errorDialogOpen}>
                    <Dialog
                        className="relative z-10"
                        onClose={() => setErrorDialogOpen(false)}
                    >
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </TransitionChild>
                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <TransitionChild
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                        <div className="absolute top-0 right-0 pt-4 pr-4">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                                onClick={() => setErrorDialogOpen(false)}
                                            >
                                                <span className="sr-only">Close</span>
                                                <IconX className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <ExclamationTriangleIcon
                                                        className="h-6 w-6 text-red-600"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                    <DialogTitle
                                                        as="h3"
                                                        className="text-base font-semibold leading-6 text-gray-900"
                                                    >
                                                        {errorDialogTitle || 'Error'}
                                                    </DialogTitle>
                                                    <div className="mt-2 mb-2">
                                                        <p className="text-sm text-gray-500">
                                                            {errorMessage}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                            {errorDialogTitle === 'Maximum Questions Exceeded' ? (
                                                // If it's the "Maximum Questions Exceeded" error
                                                <button
                                                    onClick={() => setErrorDialogOpen(false)}
                                                    className="inline-flex justify-center rounded-md bg-blue-600 hover:bg-blue-700
                             px-4 py-2 text-sm font-semibold text-white shadow-sm
                             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                             focus-visible:outline-blue-600"
                                                >
                                                    OK
                                                </button>
                                            ) : (
                                                // Otherwise show the Upgrade button
                                                <button
                                                    onClick={() => {
                                                        setErrorDialogOpen(false);
                                                        navigate('/upgrade');
                                                    }}
                                                    className="inline-flex justify-center rounded-md bg-green-600 hover:bg-green-700
                             px-4 py-2 text-sm font-semibold text-white shadow-sm
                             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                             focus-visible:outline-blue-600"
                                                >
                                                    Upgrade Now
                                                </button>
                                            )}
                                        </div>
                                    </DialogPanel>
                                </TransitionChild>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>

        </>
    );
};

export default AddManual;
