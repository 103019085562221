import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { auth } from "./config/firebase";
import { applyActionCode } from "firebase/auth";
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';

export function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const oobCode = searchParams.get("oobCode");

    if (oobCode) {
      applyActionCode(auth, oobCode)
        .then(() => {
          setMessage("Your email has been verified successfully! Redirecting to login...");
          // Redirect after a few seconds
          setTimeout(() => {
            navigate("/auth?type=login");
          }, 3000);
        })
        .catch((err) => {
          console.error("Error verifying email:", err);
          setError("The verification link is invalid or has expired.");
        });
    } else {
      setError("Invalid request. No verification code provided.");
    }
  }, [searchParams, navigate]);

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center py-6 sm:px-6 lg:px-8 bg-gray-100">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-20 w-auto"
          src={logo}
          alt="Your Company"
        />
        <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Email Verification
        </h2>
      </div>
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {error ? (
            <div className="mb-4 text-red-600 bg-red-100 border border-red-400 rounded p-4 text-center">
              {error}
            </div>
          ) : (
            <p className="text-center text-gray-800">{message}</p>
          )}
        </div>
      </div>
    </div>
  );
}
