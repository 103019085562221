import React, { useEffect, useState } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import { Dialog, Tab, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon, PlusIcon } from '@heroicons/react/24/outline';
import logo from './assets/logo_roboto.png';
import genQuestionScreenshot from './assets/gen_question_screenshot.png'
import aiTutorScreenshot from './assets/ai_tutor_screenshot.png'
import evalScreenshot from './assets/eval_screenshot.png'
import apiKeyScreenshot from './assets/api_key_screenshot.png'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Firestore, collection, getDocs, query, where, addDoc, onSnapshot } from 'firebase/firestore';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { PlayIcon } from '@heroicons/react/24/solid';
import { CodeBracketIcon, PresentationChartLineIcon, ChartBarIcon, BeakerIcon, PaintBrushIcon, CalendarIcon } from '@heroicons/react/24/outline';
import logoText from './assets/logo_text.png'
// import logoLato from './assets/logo_lato.png'
import logoLato from './assets/logo_arimo_v3.png'
import './App.css';
import { db } from './config/firebase';
import progress from './assets/progress.png'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { AdjustmentsHorizontalIcon, ClockIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon, SparklesIcon } from "@heroicons/react/24/outline";


import { IconBrandYoutube } from '@tabler/icons-react'
import tutorImage from './assets/hgtp_tutor_ss.png'
import evaluationImage from './assets/hgpt_submit_ss.png'
import generateImage from './assets/hgpt_generate_ss.png'
import badgesImage from './assets/badges_ss.png'
import statsImage from './assets/stats_ss.png'
import afterImage from './assets/_19aae867-c058-4205-a803-e00853524515.jpeg'
import beforeImage from './assets/_a01ffb89-752a-42e8-b381-becd4f668224.jpeg'

import activityCalendar from './assets/calendar_ss.png'
import { ChatBubbleOvalLeftEllipsisIcon, HeartIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { MinusIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/20/solid'
import landing_ss from './assets/trie_example.png'
import question_arrow from './assets/question_arrow.svg'
import solution_arrow from './assets/solution_arrow.svg'
import tutor_arrow from './assets/tutor_arrow.svg'
import question_gen_vid from './assets/gen_1080.mp4'
import tutor_vid from './assets/tutor_1080.mp4'
import solution_vid from './assets/solution_1080.mp4'
import cartoon from './assets/landing_page_cartoon.png'
import activity_ss from './assets/activity_ss_lg.png'
import gpt4o_image from './assets/gpt-4o.png'
import gpt4omini_image from './assets/gpt-4o-mini.png'
import o1mini_image from './assets/o1-mini.png'
import o3mini_image from './assets/o3-mini.png'




const navigation = [
    { name: 'How it works', href: '#how-it-works' },
    { name: 'Pricing', href: '#pricing' },
    { name: 'FAQ', href: '#faq' },
    { name: 'Blog', href: '/blog' },
];

type TierName = 'HinterviewGPT' | 'InterviewBit' | 'LeetCode';


const tiers: { name: TierName; id: string; href: string; priceMonthly: string; mostPopular: boolean }[] = [
    { name: 'HinterviewGPT', id: 'tier-starter', href: '#', priceMonthly: '$19', mostPopular: true },
    { name: 'InterviewBit', id: 'tier-growth', href: '#', priceMonthly: '$49', mostPopular: false },
    { name: 'LeetCode', id: 'tier-scale', href: '#', priceMonthly: '$99', mostPopular: false },
    // { name: 'HackerRank', id: 'tier-scale', href: '#', priceMonthly: '$99', mostPopular: false },

];

const sections = [
    {
        name: 'Features',
        sec_features: [
            { name: 'Algorithms & data structures practice', tiers: { HinterviewGPT: true, InterviewBit: true, LeetCode: true } },
            { name: 'System design practice', tiers: { HinterviewGPT: true, InterviewBit: true, LeetCode: false } },
            { name: 'Questions for multiple industries/roles', tiers: { HinterviewGPT: true, InterviewBit: false, LeetCode: false } },
            { name: 'Custom AI question generation', tiers: { HinterviewGPT: true, InterviewBit: false, LeetCode: false } },
            { name: 'Real-time AI tutor guidance', tiers: { HinterviewGPT: true, InterviewBit: false, LeetCode: false } },
            { name: 'Personalized solution feedback', tiers: { HinterviewGPT: true, InterviewBit: false, LeetCode: false } },

        ],
    }
]


const faqs = [
    {
        question: "Is HinterviewGPT just for software engineering interviews?",
        answer: "No! software engineering interviews are just a small subset of the types of interviews that HinterviewGPT can be used for.  HinterviewGPT can generate realistic interview questions for virtually any role.  It can also evaluate the correctness of your solution for virtually any realistic interview question."
    },

    {
        question: "What if the answer type of the question I want to practice is code-based? Text-based? Math-based?",
        answer: "That's fine.  HinterviewGPT can handle all of these cases.  With code-based questions, HinterviewGPT has a built-in code editor with syntax highlighting.  For text and math questions, HinterviewGPT has a rich text editor."
    },
    {
        question: "Does HinterviewGPT offer a free trial?",
        answer: "Yes, HinterviewGPT offers a 7 day free trial.  You can sign up for free and try it out for yourself.  If you like it, you can upgrade to a paid plan at any time."
    },

    {
        question: "Is HinterviewGPT ever wrong?",
        answer: "Yes, HinterviewGPT can be wrong.  It is possible for HinterviewGPT to incorrectly evaluate your solution.  HinterviewGPT's accuracy greatly depends on the model you have selected.  GPT-4o is the model that generally provides the best results.  We are confident that HinterviewGPT is accurate enough to provide a valuable study tool for practicing interview questions.",
    },
    {
        question: "Which OpenAI models does HinterviewGPT use?",
        answer: "HinterviewGPT currently offers two OpenAI flagship models: GPT-4o, and GPT-4o-mini and two OpenAI reasoning models: o1-mini and o3-mini.  Each model has its own strengths and weaknesses.  You can learn more about each model by visiting the <a href='https://platform.openai.com/docs/models' target='_blank' rel='noopener noreferrer' class='text-indigo-600 hover:text-indigo-900'>OpenAI Model Documentation</a>.",
    },
    {
        question: "What if I already have a question in mind that I want to practice?",
        answer: "This is fine.  Generating a question is only one way to add a question.  You can also add a question manually.  Simply provide the title, body, tags and answer type and begin practicing.",
    },
    {
        question: "Can I use Claude models with HinterviewGPT?",
        answer: "Currently, HinterviewGPT only supports OpenAI models.  However adding models from other providers is on the roadmap.",
    },
];


const features = [
    {
        name: 'Software Engineers',
        description:
            'Practice realistic algorithms, data structures, system design, and behavioral interview questions.',
        icon: CodeBracketIcon,
    },
    {
        name: 'Product Managers',
        description:
            'Practice realistic PM interview questions covering strategy, market analysis, and cross-functional collaboration.',
        icon: PresentationChartLineIcon,
    },
    {
        name: 'Data Analysts',
        description:
            'Practice realistic questions on data analysis, visualization, and data-driven decision-making.',
        icon: ChartBarIcon,
    },
    {
        name: 'Data Scientists',
        description:
            'Practice technical questions on machine learning, statistical analysis, and data modeling.',
        icon: BeakerIcon,
    },
    {
        name: 'UX/UI Designers',
        description:
            'Prepare for design interviews with questions about user research, interaction design, and portfolio presentations.',
        icon: PaintBrushIcon,
    },
    {
        name: 'And Many More...',
        description:
            'From sales to marketing, customer support, and beyond HinterviewGPT provides tailored practice across many fields.',
        icon: PlusIcon,
    },
]

const analytics = [
    {
        name: 'Activity calendar',
        description:
            'See how your activity has evolved over time on a daily basis with our github style calendar.',
        icon: CalendarIcon,
    },
    {
        name: 'Earn badges',
        description:
            `Want a Submission Slamin' Salmon badge? Keep motivated by earning badges for your activity streaks.`,
        icon: CheckBadgeIcon,
    },
    {
        name: 'Stat tracking',
        description:
            'Track the number of questions you have practiced in the last month and all time.',
        icon: ChartBarIcon,
    },
    {
        name: 'Model usage',
        description:
            'Easily check your model usage for the month to make sure you don\'t run out.',
        icon: SparklesIcon,
    },

]
// const getDifficultyColor = (difficulty:string):string => {
//     const colors = {
//         'Easy': 'bg-green-100 text-green-800',
//         'Medium': 'bg-yellow-100 text-yellow-800',
//         'Advanced': 'bg-red-100 text-red-800'
//     };
//     return colors[difficulty] || 'bg-gray-100 text-gray-800';
// };

interface Price {
    id: string;  // Add id to represent the price document ID
    currency: string;
    unit_amount: number;
    interval: 'day' | 'week' | 'month' | 'year';
}


interface Product {
    name: string;
    description: string;
    prices: Price[];
    features: string[];
}

const featuresEg = [
    {
        name: 'Monitor Daily Activity',
        description: 'Keep track of the number of questions you practice each day to maintain consistency.',
        icon: ChartBarIcon,
    },
    {
        name: 'Earn Achievement Badges',
        description: 'Unlock badges as you reach milestones and stay motivated throughout your preparation.',
        icon: CheckBadgeIcon,
    },
    {
        name: 'View Detailed Statistics',
        description: 'Analyze your progress over the last month and all time to identify trends and areas for improvement.',
        icon: AdjustmentsHorizontalIcon,
    },
    // {
    //     name: 'Review Past Performance',
    //     description: 'Access your history of practice sessions and evaluations anytime.',
    //     icon: ClockIcon,
    // },
];




function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const LandingPage = () => {
    const [expanded, setExpanded] = useState<string | false>('');
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageSrc, setCurrentImageSrc] = useState('');
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState<'success' | 'error' | ''>('');
    // NEW: Tracks if the user has scrolled down from the top
    const [hasScrolled, setHasScrolled] = useState(false);
    const [showVideo, setShowVideo] = useState(false);


    // The existing approach for hiding the header on downward scroll:
    const [hideHeader, setHideHeader] = useState(false);
    const SCROLL_DELTA = 10; // Adjust sensitivity here

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        function handleScroll() {
            const currentScrollY = window.pageYOffset;

            // Toggle hideHeader logic (already in your code)
            if (currentScrollY - lastScrollY > SCROLL_DELTA) {
                setHideHeader(true);
            } else if (lastScrollY - currentScrollY > 0) {
                setHideHeader(false);
            }
            lastScrollY = currentScrollY;

            // NEW: Toggle the navbar background
            if (window.scrollY > 0) {
                setHasScrolled(true);
            } else {
                setHasScrolled(false);
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const formatFeature = (text: string) => {
        // This regex matches:
        // - isolated numbers (\d+)
        // - or one of the model names (GPT-4o, GPT-4o-mini, o1-mini, o3-mini)
        // and makes sure they aren’t part of a larger word.
        const regex = /(?<!\w)(\d+|GPT-4o(?:-mini)?|o[13]-mini)(?!\w)/g;
        return text.split(regex).map((part, i) =>
            /^(?:\d+|GPT-4o(?:-mini)?|o[13]-mini)$/.test(part) ? <strong key={i}>{part}</strong> : part
        );
    };



    useEffect(() => {
        const fetchProducts = async () => {
            const productsCollection = collection(db, 'products');
            const q = query(productsCollection, where('active', '==', true));
            const querySnapshot = await getDocs(q);

            const productsData: Product[] = [];
            for (const productDoc of querySnapshot.docs) {
                const productData = productDoc.data() as Omit<Product, 'prices'>;
                const pricesCollection = collection(productDoc.ref, 'prices');
                const pricesSnapshot = await getDocs(pricesCollection);
                const prices: Price[] = pricesSnapshot.docs.map((priceDoc) => {
                    const priceData = priceDoc.data() as Omit<Price, 'id'>;  // Exclude id
                    return {
                        id: priceDoc.id,  // Manually add the Firestore ID
                        ...priceData,     // Spread the rest of the price data
                    };
                });

                const features = productData.name === 'Basic'
                    ? ['250 calls/month for GPT-4o-mini']
                    : productData.name === 'Standard'
                        ? ['500 calls/month for GPT-4o-mini', '250 calls/month for GPT-4o', '100 calls/month for o1-mini', '100 calls/month for o3-mini']
                        : productData.name === 'Advanced'
                            ? ['750 calls/month for GPT-4o-mini', '500 calls/month for GPT-4o', '200 calls/month for o1-mini', '200 calls/month for o3-mini']
                            : ['Basic feature 1', 'Basic feature 2'];

                productsData.push({ ...productData, prices, features });
            }
            setProducts(productsData);
        };

        fetchProducts();
    }, []);


    const handleCheckout = async (priceId: string) => {
        const user = auth.currentUser;

        if (!user) {
            // If the user isn't logged in, redirect them to the login page
            window.location.href = '/auth';
            return;
        }

        const checkoutSessionRef = collection(db, 'customers', user.uid, 'checkout_sessions');

        try {
            const docRef = await addDoc(checkoutSessionRef, {
                price: priceId,
                success_url: `${window.location.origin}/success`, // Redirect to the success page after payment
                cancel_url: window.location.origin,
            });

            // Listen for the session URL from Stripe and redirect the user
            const unsubscribe = onSnapshot(docRef, (snap) => {
                const { error, url } = snap.data()!;
                if (error) {
                    alert(`An error occurred: ${error.message}`);
                }
                if (url) {
                    window.location.assign(url); // Redirect to Stripe Checkout
                }
            });
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('An error occurred while creating the checkout session.');
        }
    };

    // Add this function inside your LandingPage component
    const handleSubscribe = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'mailingList'), { email });
            setAlertMessage('Thanks for subscribing!');
            setAlertType('success');
            setEmail(''); // Clear the input field on success
        } catch (error) {
            console.error('Error adding email to mailing list: ', error);
            setAlertMessage('Failed to subscribe. Please try again later.');
            setAlertType('error');
        }
    };



    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'HinterviewGPT - Generate and Practice Interview Questions with AI';

    }, []);

    return (
        <div className="scroll-smooth">

            <div className='bg-gray-100'>
                <div className="flex items-center gap-x-6 bg-gray-700 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                    <p className="text-sm/6 text-white">
                        <a href="#">
                            <strong className="font-semibold">Launch</strong>
                            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline size-0.5 fill-current">
                                <circle r={1} cx={1} cy={1} />
                            </svg>
                            HinterviewGPT is live on Product Hunt! 🚀 &nbsp;<span aria-hidden="true">&rarr;</span>
                        </a>
                    </p>
                    <div className="flex flex-1 justify-end">

                    </div>
                </div>

                <header
                    className={`
                        sticky top-0 z-50 transition-all duration-300
                        ${hasScrolled ? 'bg-white/50 backdrop-blur-sm' : 'bg-gray-100'}
                        
                      `}
                >
                    <nav aria-label="Global" className="mx-auto
      flex
      max-w-7xl
      items-center
      justify-between
      px-4
      py-3  
      lg:px-8">
                        <div className="flex lg:flex-1">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    alt=""
                                    src={logoLato}
                                    className="h-8 w-auto"
                                />
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(true)}
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon aria-hidden="true" className="size-6" />
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            {navigation.map((item) => (
                                <a key={item.name} href={item.href} className="text-sm/6 font-semibold text-gray-900">
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                            {user ? (
                                <a
                                    href="/activity"
                                    className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                >
                                    Dashboard
                                </a>
                            ) : (
                                <>
                                    <a
                                        href="/auth?type=login"
                                        className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                    >
                                        Log In
                                    </a>
                                    <a
                                        href="/auth?type=register"
                                        className="ml-4 rounded-md border border-indigo-600 px-3 py-1.5 text-md font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Register
                                    </a>
                                </>
                            )}
                        </div>
                    </nav>
                    <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                        <div className="fixed inset-0 z-10" />
                        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div className="flex items-center justify-between">
                                {/* <a href="/" className="-m-1.5 p-1.5">
                                    <span className="sr-only">Your Company</span>
                                    <img
                                        alt=""
                                        src={logoLato}
                                        className="h-8 w-auto"
                                    />
                                </a> */}
                                <button
                                    type="button"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon aria-hidden="true" className="size-6" />
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                                onClick={() => setMobileMenuOpen(false)}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="py-6">
                                        {user ? (
                                            <a
                                                href="/activity"
                                                className="rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                            >
                                                Dashboard
                                            </a>
                                        ) : (
                                            <>
                                                <a
                                                    href="/auth?type=login"
                                                    className="block w-full rounded-md bg-indigo-600 px-4 py-2 text-base font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                                >
                                                    Log In
                                                </a>
                                                <a
                                                    href="/auth?type=register"
                                                    className="mt-3 block w-full rounded-md border border-indigo-600 px-4 py-2 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white transition duration-200"
                                                >
                                                    Register
                                                </a>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </Dialog>

                </header>
                <div
                    // style={{
                    //     backgroundImage: `url("data:image/svg+xml,%3Csvg%20width='1440'%20height='320'%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%201440%20320'%3E%3Cpath%20fill='%236b46c1'%20fill-opacity='1'%20d='M0,46L60,67.3C120,89,240,131,360,152.7C480,174,600,174,720,163.3C840,153,960,131,1080,99.3C1200,67,1320,25,1380,3.3L1440,-18L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z'%3E%3C/path%3E%3C/svg%3E")`,
                    //     backgroundSize: "cover",
                    //     backgroundRepeat: "no-repeat",
                    //     backgroundPosition: "center"
                    // }}
                    className="py-20 sm:py-30"
                >

                    <div className="mx-auto max-w-6xl px-6 lg:px-8">
                        <div className="mx-auto max-w-3xl text-center font-sans">
                            <div className="hidden sm:flex sm:justify-center">
                                <div className="relative inline-flex items-center gap-x-2 
                  rounded-full bg-gradient-to-r 
                  from-indigo-50 via-purple-50 to-blue-50 
                  px-4 py-2 
                  text-sm font-medium text-indigo-700 
                  ring-1 ring-inset ring-indigo-200 
                  shadow-sm 
                  transition 
                  hover:scale-105 hover:shadow-md">
                                    <SparklesIcon className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                                    #1 AI Interview Prep Tool
                                </div>
                            </div>
                            {/* Animated main heading */}
                            <h1
                                className="pt-4 text-6xl font-semibold tracking-tight text-gray-900 sm:text-7xl"

                            >
                                Interview Prep Meets{" "}
                                <span className="bg-gradient-to-r from-indigo-500 via-purple-400 to-blue-400 bg-clip-text text-transparent">
                                    Generative AI
                                </span>
                            </h1>
                            <p
                                className="mt-6 text-lg font-medium leading-8 text-gray-600"

                            >
                                Generate realistic interview questions and practice them with your own personal AI tutor,
                                helping you prepare for your next big opportunity.
                            </p>

                            <div
                                className="mt-8 flex items-center justify-center gap-x-6"

                            >
                                <a
                                    href={user ? "/activity" : "/auth"}
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 
                     focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Get started
                                </a>
                                <a
                                    href="#how-it-works"
                                    className="rounded-md border border-indigo-600 bg-white px-3.5 py-2.5 text-md font-semibold text-indigo-600 
                     hover:bg-indigo-600 hover:text-white 
                     focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Learn more
                                </a>
                            </div>
                        </div>

                        <div className="mt-16 flow-root sm:mt-24">
                            {/* Add 'group' so children can inherit hover state */}
                            <div
                                onClick={() => setShowVideo(true)}
                                className="-m-2 rounded-xl bg-gray-200 p-2 ring-1 ring-inset ring-gray-900/10 
               lg:-m-4 lg:rounded-2xl lg:p-4 relative cursor-pointer group"
                            >
                                <div className="relative w-full">
                                    <img
                                        alt="App screenshot"
                                        src={landing_ss}
                                        width={2432}
                                        height={1642}
                                        className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                                    />
                                    <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                                        <div className="relative inline-flex items-center justify-center">
                                            {/* Background circle that changes on hover via 'group-hover:' */}
                                            <div className="absolute rounded-full bg-black/60 p-9 transition group-hover:bg-black/80"></div>
                                            {/* Play icon that scales up on hover via 'group-hover:' */}
                                            <PlayIcon className="relative w-10 h-10 text-white transition group-hover:scale-110" />
                                        </div>
                                    </div>
                                </div>

                                {/* Question Arrow */}
                                <img
                                    src={question_arrow}
                                    alt="Question arrow"
                                    className="hidden md:block absolute top-[2%] left-[-14%] w-48 h-48 transform -rotate-12"
                                />
                                {/* Solution Arrow */}
                                <img
                                    src={solution_arrow}
                                    alt="Solution arrow"
                                    className="hidden md:block absolute top-[0%] right-[-13%] w-48 h-48 transform rotate-12"
                                />
                                {/* Tutor Arrow */}
                                <img
                                    src={tutor_arrow}
                                    alt="Tutor arrow"
                                    className="hidden md:block absolute top-[60%] right-[-13%] w-48 h-48 transform -rotate-6"
                                />
                            </div>
                        </div>


                    </div>
                </div>

                {/* Horizontal Divider */}
                <div id="how-it-works" className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="border-t border-gray-300 my-12"></div>
                </div>

                <div className="scroll-mt-16 overflow-hidden py-8 sm:py-12 bg-gray-100">
                    <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8 mb-12">
                        <h2 className="    inline-block px-4 
    py-2 
 text-lg font-semibold text-indigo-600 rounded-full 
    bg-indigo-100 ">Generate, Practice, Submit</h2>


                        <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            How HinterviewGPT Works
                        </p>

                    </div>
                    <div className="mx-auto max-w-6xl md:px-6 lg:px-8">
                        {/* Step 1 */}
                        <div className="mb-12 bg-white rounded-2xl p-8 shadow-sm">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:items-start">
                                <div className="lg:pr-2">
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <h2 className="text-2xl font-semibold text-indigo-600 sm:text-3xl">Step 1:</h2>
                                            <p className="text-pretty text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                                                Generate a Question
                                            </p>
                                        </div>
                                        <p className="mt-6 text-lg/8 text-gray-500">
                                            The first step is to generate a question to practice. With a nifty chat interface, HinterviewGPT can generate
                                            realistic interview questions based on
                                            a description of your job role, industry or just general topics.
                                            <a href={user ? "/activity" : "/auth"} className="whitespace-nowrap font-semibold ml-2 text-indigo-600 hover:text-indigo-700">
                                                Get started <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center">
                                    <dl className="max-w-xl space-y-4 text-base/7 text-gray-600 pt-10">
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">Generate realistic & diverse interview questions</dd>
                                        </div>
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">Generate code and text-based questions</dd>
                                        </div>
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">Manual Question Entry also Possible</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div className="mt-10 relative isolate overflow-hidden bg-indigo-500 rounded-2xl">
                                <div
                                    aria-hidden="true"
                                    className="w-full origin-bottom-left bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                                />
                                <div className="mx-auto">
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        className="w-full h-auto rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                                    >
                                        <source src={question_gen_vid} type="video/mp4" />
                                    </video>
                                </div>
                                <div
                                    aria-hidden="true"
                                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 rounded-2xl"
                                />
                            </div>
                        </div>

                        {/* Arrow between steps */}
                        <div className="flex justify-center my-12">
                            <div className="flex items-center justify-center h-24 w-24 rounded-full bg-indigo-100">
                                <ArrowDownIcon className="h-16 w-16 text-indigo-600" />
                            </div>
                        </div>


                        {/* Step 2 */}
                        <div className="mb-12 bg-white rounded-2xl p-8 shadow-sm">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:items-start">
                                <div className="lg:pr-2">
                                    <div>
                                        <div className="flex items-center gap-4 min-w-[800px]">
                                            <h2 className="text-2xl font-semibold text-indigo-600 sm:text-3xl whitespace-nowrap">Step 2:</h2>
                                            <p className="text-pretty text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                                                Practice with your AI Tutor
                                            </p>
                                        </div>
                                        <p className="mt-6 text-lg/8 text-gray-600">
                                            The next step is to practice your question on the whiteboard with your own personal AI tutor.
                                            Your tutor is aware of the question and your solution and is always ready to help if you get stuck.
                                            <a href={user ? "/activity" : "/auth"} className="whitespace-nowrap font-semibold ml-2 text-indigo-600 hover:text-indigo-700">
                                                Get started <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center">
                                    <dl className="max-w-xl space-y-4 text-base/7 text-gray-600 pt-10">
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">Never get stuck on a question!</dd>
                                        </div>
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">Tutor can see the question and the whiteboard</dd>
                                        </div>
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">Tutor provides hints not solutions</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div className="mt-10 relative isolate overflow-hidden bg-indigo-500 rounded-2xl">
                                <div
                                    aria-hidden="true"
                                    className="w-full origin-bottom-left bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                                />
                                <div className="mx-auto">
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        className="w-full h-auto rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                                    >
                                        <source src={tutor_vid} type="video/mp4" />
                                    </video>
                                </div>
                                <div
                                    aria-hidden="true"
                                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 rounded-2xl"
                                />
                            </div>
                        </div>

                        {/* Arrow between steps */}
                        <div className="flex justify-center my-12">
                            <div className="flex items-center justify-center h-24 w-24 rounded-full bg-indigo-100">
                                <ArrowDownIcon className="h-16 w-16 text-indigo-600" />
                            </div>
                        </div>

                        {/* Step 3 */}
                        <div className="mb-12 bg-white rounded-2xl p-8 shadow-sm">
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:items-start">
                                <div className="lg:pr-2">
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <h2 className="text-2xl font-semibold text-indigo-600 sm:text-3xl">Step 3:</h2>
                                            <p className="text-pretty text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                                                Submit your Solution
                                            </p>
                                        </div>
                                        <p className="mt-6 text-lg/8 text-gray-600">
                                            Once you are done, submit your solution to the question. HinterviewGPT will then give you personalized feedback on your solution which you can access at any time.
                                            <a href={user ? "/activity" : "/auth"} className="whitespace-nowrap font-semibold ml-2 text-indigo-600 hover:text-indigo-700">
                                                Get started <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center">
                                    <dl className="max-w-xl space-y-4 text-base/7 text-gray-600 pt-10">
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">Personalized AI-powered evaluation</dd>
                                        </div>
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">See where you went wrong and where you went right</dd>
                                        </div>
                                        <div className="relative pl-8">
                                            <dt className="inline font-semibold text-gray-900">
                                                <CheckCircleIcon aria-hidden="true" className="absolute left-0 size-7 text-indigo-600" />
                                            </dt>{' '}
                                            <dd className="inline">Access past solutions at any time</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div className="mt-10 relative isolate overflow-hidden bg-indigo-500 rounded-2xl">
                                <div
                                    aria-hidden="true"
                                    className="w-full origin-bottom-left bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                                />
                                <div className="mx-auto">
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        className="w-full h-auto rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                                    >
                                        <source src={solution_vid} type="video/mp4" />
                                    </video>
                                </div>
                                <div
                                    aria-hidden="true"
                                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 rounded-2xl"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Horizontal Divider */}
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="border-t border-gray-300 my-12"></div>
                </div>

                <div className="overflow-hidden bg-gray-100">
                    <div className="relative mx-auto max-w-7xl px-6 py-16 lg:px-8">
                        <div className="absolute bottom-0 left-3/4 top-0 hidden w-screen bg-gray-100 lg:block" />
                        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
                            <div>
                                <h2 className="text-lg font-semibold text-indigo-600 rounded-full bg-indigo-100 px-4 py-2 inline-block">Never get stuck again</h2>
                                <h3 className="mt-4 text-3xl/8 font-bold tracking-tight text-gray-900 sm:text-4xl">Why use HinterviewGPT?</h3>
                            </div>
                        </div>
                        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                            <div className="hidden lg:block relative lg:col-start-2 lg:row-start-1">
                                <svg
                                    fill="none"
                                    width={404}
                                    height={384}
                                    viewBox="0 0 404 384"
                                    aria-hidden="true"
                                    className="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block -mt-40"
                                >
                                    <defs>
                                        <pattern
                                            x={0}
                                            y={0}
                                            id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
                                        </pattern>
                                    </defs>
                                    <rect fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" width={404} height={384} />
                                </svg>
                                <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                                    <figure>
                                        <img
                                            alt="Whitney leaning against a railing on a downtown street"
                                            src={cartoon}
                                            width={1184}
                                            height={1376}
                                            className="aspect-[12/7] w-full rounded-lg object-cover shadow-lg lg:aspect-auto -mt-20"
                                        />

                                    </figure>
                                </div>
                            </div>
                            <div className="mt-8 lg:mt-0">
                                <div className="mx-auto text-base/8 text-gray-500">
                                    <p className="mt-1">
                                        Say you have a big upcoming interview and you've set aside some time to study.  What was the typical preparation process before HinterviewGPT?
                                    </p>
                                    <p className="mt-5">
                                        Well, the first step was to collect a list of questions to practice.  You could spend hours searching the web for the perfect questions and hope they are relevant to your upcoming interview.
                                    </p>
                                    <p className="mt-5">
                                        The next step is actually practicing your questions.  Say you get stuck on a particularly difficult question.  You stare at it for about 45 minutes until you break down and look up the solution online.
                                        Then you spend another 45 minutes trying to understand the solution.  Before you know it, 1.5 hours have passed and you've barely made any progress.  Then you repeat the process for the next question.

                                    </p>
                                    <p className="mt-5">
                                        <strong>Enter HinterviewGPT</strong>.  By leveraging the power of generative AI, HinterviewGPT can generate a virtually unlimited number of realistic interview questions based on your job role, industry or just general topics.
                                    </p>

                                    <p className="mt-5">
                                        When it comes to actually practicing your questions, HinterviewGPT can be your personal AI tutor.
                                        Since HinterviewGPT is aware of the question and your solution, it can provide hints and guidance when you get stuck which
                                        promotes <strong>active learning</strong>.
                                    </p>
                                    <p className="mt-5">
                                        Finally, once you are done, you can submit your solution to the question.  HinterviewGPT will then give you personalized feedback on your solution.  Past questions and solutions  can be accessed at any time for future reference.
                                        <a href={user ? "/activity" : "/auth"} className="whitespace-nowrap font-semibold ml-2 text-indigo-600 hover:text-indigo-700">
                                            Get started <span aria-hidden="true">&rarr;</span>
                                        </a>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden lg:block mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="border-t border-gray-300 my-12"></div>
                </div>



                <div className=" hidden isolate mx-auto max-w-7xl lg:block py-8 sm:py-12 bg-gray-100">
                    <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8 mb-16">
                        <h2 className="text-lg font-semibold text-indigo-600 rounded-full bg-indigo-100 px-4 py-2 inline-block">The AI difference</h2>
                        <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            HinterviewGPT vs other tools
                        </p>

                    </div>
                    <div className="relative px-8">
                        {tiers.some((tier) => tier.mostPopular) ? (
                            <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                                <div
                                    style={{ marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 25}%` }}
                                    aria-hidden="true"
                                    className="flex w-1/4 px-4"
                                >
                                    <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                                </div>
                            </div>
                        ) : null}
                        <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                            <colgroup>
                                <col className="w-1/4" />
                                <col className="w-1/4" />
                                <col className="w-1/4" />
                                <col className="w-1/4" />
                            </colgroup>
                            <thead>
                                <tr className="mb-0">
                                    <td />
                                    {tiers.map((tier) => (
                                        <th
                                            key={tier.id}
                                            scope="col"
                                            className="px-4 py-1 text-center align-middle mb-0"
                                        >
                                            <span className="text-lg font-semibold text-gray-900">
                                                {tier.name}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>


                            <tbody className="-mt-2">
                                <tr>
                                    <th scope="row">
                                        <span className="sr-only">Price</span>
                                    </th>
                                    {tiers.map((tier) => (
                                        <td key={tier.id} className="px-6 pt-0 pb-0 xl:px-8">


                                        </td>
                                    ))}
                                </tr>
                                {sections.map((section, sectionIdx) => (
                                    <Fragment key={section.name}>
                                        <tr>
                                            <th
                                                scope="colgroup"
                                                colSpan={4}
                                                className={classNames(
                                                    sectionIdx === 0 ? 'pt-8' : 'pt-16',
                                                    'pb-4 text-sm/6 font-semibold text-gray-900',
                                                )}
                                            >
                                                {section.name}
                                                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                                            </th>
                                        </tr>
                                        {section.sec_features.map((feature) => (
                                            <tr key={feature.name}>
                                                <th scope="row" className="py-4 text-sm/6 font-normal text-gray-900">
                                                    {feature.name}
                                                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                                                </th>
                                                {tiers.map((tier) => (
                                                    <td key={tier.id} className="px-6 py-4 xl:px-8">
                                                        {typeof feature.tiers[tier.name] === 'string' ? (
                                                            <div className="text-center text-sm/6 text-gray-500">{feature.tiers[tier.name]}</div>
                                                        ) : (
                                                            <>
                                                                {feature.tiers[tier.name] === true ? (
                                                                    <CheckIcon aria-hidden="true" className="mx-auto size-5 text-indigo-600" />
                                                                ) : (
                                                                    <MinusIcon aria-hidden="true" className="mx-auto size-5 text-gray-400" />
                                                                )}

                                                                <span className="sr-only">
                                                                    {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                                                </span>
                                                            </>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Horizontal Divider */}
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="border-t border-gray-300 my-12"></div>
                </div>

                <div className="mx-auto max-w-7xl px-6 lg:px-8 my-12">
                    <div className="to-white p-8 pb-0">
                        <div className="text-center">
                            <h2 className="text-lg font-semibold text-indigo-600 rounded-full bg-indigo-100 px-4 py-2 inline-block">
                                Under the hood
                            </h2>
                        </div>

                        <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
                            HinterviewGPT Models
                        </p>
                        <p className="mt-6 text-lg text-gray-600 text-center">
                            HinterviewGPT uses advanced large language models for question generation and
                            specialized reasoning models for precise, context-aware feedback.  You chose when and where to use each model.
                            <a href={user ? "/activity" : "/auth"} className="whitespace-nowrap font-semibold ml-2 text-indigo-600 hover:text-indigo-700">
                                Get started <span aria-hidden="true">&rarr;</span>
                            </a>
                        </p>

                        {/* Two-column layout */}
                        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
                            {/* Left Column: Flagship Chat Models */}
                            <div>
                                <h3 className="text-xl font-semibold text-gray-700 mb-4">
                                    Flagship Chat Models
                                </h3>

                                {/* GPT-4o */}
                                <div className="flex items-start mb-6  p-4 rounded-lg bg-white">
                                    <img
                                        src={gpt4o_image}
                                        alt="GPT-4o"
                                        className="w-16 h-16 mr-4 object-contain"
                                    />
                                    <div>
                                        <h4 className="text-lg font-medium text-gray-900">GPT-4o</h4>
                                        <p className="text-gray-600 text-sm mt-1">
                                            Fast, intelligent, flexible GPT model.
                                        </p>
                                    </div>
                                </div>

                                {/* GPT-4o-mini */}
                                <div className="flex items-start mb-6  p-4 rounded-lg  bg-white">
                                    <img
                                        src={gpt4omini_image}
                                        alt="GPT-4o-mini"
                                        className="w-16 h-16 mr-4 object-contain"
                                    />
                                    <div>
                                        <h4 className="text-lg font-medium text-gray-900">GPT-4o-mini</h4>
                                        <p className="text-gray-600 text-sm mt-1">
                                            Fast, affordable small model for focused tasks.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* Right Column: Reasoning Models */}
                            <div>
                                <h3 className="text-xl font-semibold text-gray-700 mb-4">
                                    Reasoning Models
                                </h3>

                                {/* o1-mini */}
                                <div className="flex items-start mb-6  p-4 rounded-lg  bg-white">
                                    <img
                                        src={o1mini_image}
                                        alt="o1-mini"
                                        className="w-16 h-16 mr-4 object-contain"
                                    />
                                    <div>
                                        <h4 className="text-lg font-medium text-gray-900">o1-mini</h4>
                                        <p className="text-gray-600 text-sm mt-1">
                                            A faster, more affordable reasoning model.
                                        </p>
                                    </div>
                                </div>

                                {/* o3-mini */}
                                <div className="flex items-start mb-6  p-4 rounded-lg  bg-white">
                                    <img
                                        src={o3mini_image}
                                        alt="o3-mini"
                                        className="w-16 h-16 mr-4 object-contain"
                                    />
                                    <div>
                                        <h4 className="text-lg font-medium text-gray-900">o3-mini</h4>
                                        <p className="text-gray-600 text-sm mt-1">
                                            Fast, flexible, intelligent reasoning model.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end pr-8 pb-6">
                        <a
                            href="https://platform.openai.com/docs/models"
                            className="whitespace-nowrap font-semibold text-indigo-600 hover:text-indigo-700"
                        >
                            More model info <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </div>


                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="border-t border-gray-300 my-12"></div>
                </div>


                <div className="overflow-hidden bg-gray-100 py-8 sm:py-12 ">
                    <div className="mx-auto max-w-7xl md:px-4 lg:px-6">
                        <div className="grid grid-cols-1 gap-x-0 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                            <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                                    <h2 className="text-base/7 font-semibold text-indigo-600 rounded-full bg-indigo-100 px-4 py-2 inline-block">Track your progress</h2>
                                    <p className="mt-4 text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                                        Measure your milestones
                                    </p>
                                    <p className="mt-6 text-lg/8 text-gray-600">
                                        HinterviewGPT also provides comprehensive analytics to help you monitor your progress and keep you motivated insuring you stay on the right track.
                                        <a href={user ? "/activity" : "/auth"} className="whitespace-nowrap font-semibold ml-2 text-indigo-600 hover:text-indigo-700">
                                            Get started <span aria-hidden="true">&rarr;</span>
                                        </a>
                                    </p>
                                    <dl className="mt-10 max-w-xl space-y-8 text-base/8 text-gray-600 lg:max-w-none">
                                        {analytics.map((feature) => (
                                            <div key={feature.name} className="relative pl-9">
                                                <dt className="inline font-semibold text-gray-900">
                                                    <feature.icon aria-hidden="true" className="absolute left-0 top-1 size-7 text-indigo-600" />
                                                    {feature.name}
                                                </dt>{' '}
                                                <dd className="inline">{feature.description}</dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>
                            </div>
                            <div className="sm:px-6 lg:px-0">
                                <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
                                    <div
                                        aria-hidden="true"
                                        className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                                    />
                                    <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                                        <img
                                            alt="Product screenshot"
                                            src={activity_ss}
                                            width={2432}
                                            height={1442}
                                            className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                                        />
                                    </div>
                                    <div
                                        aria-hidden="true"
                                        className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="border-t border-gray-300 my-12"></div>
                </div>




                <div className="relative bg-gray-100 py-14 sm:py-22 lg:py-22">

                    <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">

                        <h2 className="text-lg font-semibold text-indigo-600 rounded-full bg-indigo-100 px-4 py-2 inline-block">Interview prep for all</h2>
                        <p className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Who is HinterviewGPT for?
                        </p>
                        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                            HinterviewGPT is designed for anyone preparing for an upcoming interview, regardless of industry or role.
                            <a href={user ? "/activity" : "/auth"} className="whitespace-nowrap font-semibold ml-2 text-indigo-600 hover:text-indigo-700">
                                Get started <span aria-hidden="true">&rarr;</span>
                            </a>

                        </p>
                        <div className="mt-12">
                            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
                                {features.map((feature) => (
                                    <div key={feature.name} className="pt-6">
                                        <div className="flow-root rounded-xl bg-white px-6 pb-8">
                                            <div className="-mt-6">
                                                <div>
                                                    <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                                                        <feature.icon aria-hidden="true" className="size-8 text-white" />
                                                    </span>
                                                </div>
                                                <h3 className="mt-8 text-lg/8 font-semibold tracking-tight text-gray-900">{feature.name}</h3>
                                                <p className="mt-5 text-base/7 text-gray-600">{feature.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>



                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="border-t border-gray-300 my-12"></div>
                </div>

                {/* Pricing Section */}
                {!user && (
                    <div id="pricing" className="scroll-mt-14 py-14 sm:py-22 lg:py-22"
                    // style={{ backgroundColor: "#f7fcff" }}
                    >
                        <div className="mx-auto max-w-6xl px-6 lg:px-8">
                            <div className="text-center mx-auto max-w-3xl">
                                <h2 className="text-4xl font-extrabold text-gray-900">Pricing</h2>
                                <p className="mt-4 text-lg text-gray-600">
                                    Whether you are casually preparing or in hardcore study mode for a big interview, HinterviewGPT has a plan for you.
                                </p>
                            </div>
                            <div className="mt-2 flow-root">
                                {products.length > 0 && (
                                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                                        {products
                                            .sort((a, b) => {
                                                const order = ["Bring Your Own Key", "Standard", "Advanced"];
                                                return order.indexOf(a.name) - order.indexOf(b.name);
                                            })
                                            .map((product, index) => (
                                                <div
                                                    key={product.name}
                                                    className={`ring-1 ring-gray-200 rounded-3xl bg-white p-8 xl:p-10 relative ${index === 1 ? "ring-indigo-600 " : ""}`}
                                                >
                                                    {index === 1 && (
                                                        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-indigo-600 text-white text-xs font-semibold px-3 py-1 rounded-full">
                                                            Most Popular
                                                        </div>
                                                    )}

                                                    <div className="flex items-center justify-between gap-x-4">
                                                        <h3 className="text-gray-900 text-lg font-semibold leading-8">
                                                            {product.name}
                                                        </h3>
                                                    </div>
                                                    <p className="mt-4 text-sm leading-6 text-gray-600">
                                                        {product.description}
                                                    </p>
                                                    <p className="mt-6 flex items-baseline gap-x-1">
                                                        <span className="text-4xl font-bold tracking-tight text-gray-900">
                                                            ${product.prices[0].unit_amount / 100}
                                                        </span>
                                                        <span className="text-sm font-semibold leading-6 text-gray-600">
                                                            /month
                                                        </span>
                                                    </p>
                                                    <a
                                                        onClick={() => handleCheckout(product.prices[0].id)}
                                                        className="w-full bg-indigo-600 cursor-pointer text-white shadow-sm hover:bg-indigo-500 mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Start Free Trial
                                                    </a>

                                                    <ul
                                                        role="list"
                                                        className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                                                    >
                                                        {product.features.map((feature) => (
                                                            <li key={feature} className="flex gap-x-3">
                                                                <CheckIcon
                                                                    aria-hidden="true"
                                                                    className="h-6 w-5 flex-none text-indigo-600"
                                                                />
                                                                <span>{formatFeature(feature)}</span>
                                                            </li>

                                                        ))}
                                                    </ul>

                                                    <div className="mx-auto px-2 lg:px-2 mt-4">
                                                        <div className="border-t border-gray-300"></div>
                                                    </div>

                                                    <ul
                                                        role="list"
                                                        className="mt-2 space-y-3 text-sm leading-6 text-gray-600 xl:mt-5"
                                                    >
                                                        <li className="flex gap-x-3">
                                                            <CheckIcon
                                                                aria-hidden="true"
                                                                className="h-6 w-5 flex-none text-indigo-600"
                                                            />
                                                            <span>Includes all features</span>
                                                        </li>
                                                        <li className="flex gap-x-3">
                                                            <CheckIcon
                                                                aria-hidden="true"
                                                                className="h-6 w-5 flex-none text-indigo-600"
                                                            />
                                                            <span>Priority support</span>
                                                        </li>
                                                        <li className="flex gap-x-3">
                                                            <CheckIcon
                                                                aria-hidden="true"
                                                                className="h-6 w-5 flex-none text-indigo-600"
                                                            />
                                                            <span>Cancel anytime</span>
                                                        </li>


                                                    </ul>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-32">
                            <div className="border-t border-gray-300 "></div>
                        </div>
                    </div>
                )}




                {/* FAQ Section */}
                <div id="faq" className="scroll-mt-14 bg-gray-100 py-14 sm:py-22 lg:py-22"
                // style={{ backgroundColor: "#f7fcff" }}
                >
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-3xl font-extrabold text-gray-900">Frequently Asked Questions</h2>
                        </div>
                        <div className="mt-12 max-w-3xl mx-auto divide-y divide-gray-200">
                            {faqs.map((faq) => (
                                <Disclosure key={faq.question} as="div" className="py-6">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex justify-between items-center w-full text-left">
                                                <span className="text-lg font-medium text-gray-900">{faq.question}</span>
                                                {open ? (
                                                    <MinusSmallIcon className="w-6 h-6 text-indigo-600" />
                                                ) : (
                                                    <PlusSmallIcon className="w-6 h-6 text-indigo-600" />
                                                )}
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-4 text-gray-600">
                                                <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </div>
                    </div>

                    {/* Image Modal */}
                    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
                        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

                        <div className="fixed inset-0 flex items-center justify-center p-4">
                            <Dialog.Panel className="mx-auto max-w-4xl">
                                <div className="relative bg-white rounded-lg shadow-lg">
                                    <button
                                        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                    <img src={currentImageSrc} alt="Enlarged" className="rounded-lg w-full h-auto" />
                                </div>
                            </Dialog.Panel>
                        </div>
                    </Dialog>
                </div>

                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="border-t border-gray-300 my-12"></div>
                </div>

                <div className="bg-gray-100 py-16 sm:py-24 lg:py-32">
                    <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
                        <h2 className="max-w-xl text-balance text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7">
                            Want <span className="text-indigo-600">HinterviewGPT</span> news and updates? Sign up for our newsletter.
                        </h2>
                        <form onSubmit={handleSubscribe} className="w-full max-w-md lg:col-span-5 lg:pt-2">
                            <div className="flex gap-x-4">
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    required
                                    placeholder="Enter your email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="min-w-0 flex-auto rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                                />
                                <button
                                    type="submit"
                                    className="flex-none rounded-md bg-indigo-600 cursor-pointer px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Subscribe
                                </button>
                            </div>
                            {alertMessage && (
                                <p className={`mt-4 text-sm ${alertType === 'success' ? 'text-green-600' : 'text-red-600'}`}>
                                    {alertMessage}
                                </p>
                            )}
                            <p className="mt-4 text-sm/6 text-gray-900">
                                We care about your data. Read our{' '}
                                <a href="/privacy" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                    privacy&nbsp;policy
                                </a>
                                .
                            </p>
                        </form>

                    </div>
                </div>
                {/* Footer */}
                <footer className="bg-gray-100 text-gray-600"
                // style={{ backgroundColor: "#f7fcff" }}
                >
                    <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8">
                        {/* Top Section: Copyright and Links */}
                        <div className="flex flex-col md:flex-row justify-between items-center">
                            {/* Copyright */}
                            <p className="text-center md:text-left text-sm">
                                © 2024 HinterviewGPT. All rights reserved.
                            </p>
                            {/* Navigation Links */}
                            <div className="mt-4 md:mt-0 flex space-x-6">
                                <a href="/privacy" className="hover:text-indigo-600 transition">
                                    Privacy Policy
                                </a>
                                <a href="/tos" className="hover:text-indigo-600 transition">
                                    Terms of Service
                                </a>
                                <a href="mailto:support@hinterviewgpt.com" className="hover:text-indigo-600 transition">
                                    Contact Us
                                </a>
                            </div>
                        </div>

                        {/* Divider */}
                        <hr className="my-8 border-gray-600" />

                        {/* Bottom Section: Social Media Icons */}
                        <div className="flex justify-center space-x-6">
                            {/* Facebook */}
                            <a
                                href="https://www.youtube.com/@hinterviewgpt" // Replace with your Facebook page URL
                                className="text-gray-400 hover:text-indigo-600 transition"
                                aria-label="Youtube"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IconBrandYoutube className="h-6 w-6" aria-hidden="true" />
                            </a>
                            {/* Twitter */}
                            <a
                                href="https://twitter.com/yourprofile" // Replace with your Twitter profile URL
                                className="text-gray-400 hover:text-white transition"
                                aria-label="Twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {/* <TwitterIcon className="h-6 w-6" aria-hidden="true" /> */}
                            </a>
                            {/* LinkedIn */}
                            <a
                                href="https://www.linkedin.com/in/yourprofile" // Replace with your LinkedIn profile URL
                                className="text-gray-400 hover:text-white transition"
                                aria-label="LinkedIn"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {/* <LinkedInIcon className="h-6 w-6" aria-hidden="true" /> */}
                            </a>
                            {/* Add more social icons as needed */}
                        </div>
                    </div>
                </footer>
            </div>

            <Dialog
                open={showVideo}
                onClose={() => setShowVideo(false)}
                className="relative z-50"
            >
                {/* Overlay */}
                <div className="fixed inset-0 bg-black/70" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <DialogPanel
                        className="
              relative 
              mx-auto 
              w-full 
              max-w-6xl  
              bg-black 
              rounded-lg 
              shadow-lg
              overflow-hidden
            "
                    >
                        {/* Close button */}
                        <button
                            className="absolute top-4 right-4 text-gray-100 hover:text-white"
                            onClick={() => setShowVideo(false)}
                        >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Aspect ratio container */}
                        <div className="relative w-full" style={{ paddingTop: '60.71%' }}>
                            <iframe
                                className="absolute top-0 left-0 h-full w-full rounded-b-lg"
                                src="https://www.youtube.com/embed/Yj6qvEQYWi0?autoplay=1"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
                                allowFullScreen
                            />
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </div>

    );
};

export default LandingPage;