import React, { useState, useEffect } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import logoText from './assets/logo_text.png'
import { Link } from 'react-router-dom';
import logoLato from './assets/logo_arimo_v3.png'
import { Dialog, Tab, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon, PlusIcon } from '@heroicons/react/24/outline';



const navigation = [
    { name: 'How it works', href: '/#how-it-works' },
    { name: 'Pricing', href: '/#pricing' },
    { name: 'FAQ', href: '/#faq' },
    { name: 'Blog', href: '/blog' },
];
const posts = [
    {
        id: 1,
        title: 'The Advantages of Hint-based Interview Prep',
        href: '#',
        description:
            'Investigating why hint-based interview prep is a better way to prepare for technical interviews.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Sept 17, 2024',
        datetime: '2020-03-16',
        category: { title: 'Interview Prep', href: '#' },

    },
    // More posts...
]


const Blog = () => {
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {

        document.title = 'Terms of Service | HinterviewGPT';

    }, []);

    return (
        <div className="bg-gray-50 min-h-screen flex flex-col">
            <header
                className={`
                        sticky top-0 z-50 transition-all duration-300
                        ${hasScrolled ? 'bg-white/50 backdrop-blur-sm' : 'bg-gray-50'}
                        
                      `}
            >
                <nav aria-label="Global" className="mx-auto
      flex
      max-w-7xl
      items-center
      justify-between
      px-4
      py-3  
      lg:px-8">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                alt=""
                                src={logoLato}
                                className="h-8 w-auto"
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="size-6" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm/6 font-semibold text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                        {user ? (
                            <a
                                href="/activity"
                                className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
                            >
                                Dashboard
                            </a>
                        ) : (
                            <>
                                <a
                                    href="/auth?type=login"
                                    className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                >
                                    Log In
                                </a>
                                <a
                                    href="/auth?type=register"
                                    className="ml-4 rounded-md border border-indigo-600 px-3 py-1.5 text-md font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Register
                                </a>
                            </>
                        )}
                    </div>
                </nav>
                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-10" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    alt=""
                                    src={logoLato}
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="size-6" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    {user ? (
                                        <a
                                            href="/activity"
                                            className="rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                        >
                                            Dashboard
                                        </a>
                                    ) : (
                                        <>
                                            <a
                                                href="/auth?type=login"
                                                className="block w-full rounded-md bg-indigo-600 px-4 py-2 text-base font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                            >
                                                Log In
                                            </a>
                                            <a
                                                href="/auth?type=register"
                                                className="mt-3 block w-full rounded-md border border-indigo-600 px-4 py-2 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white transition duration-200"
                                            >
                                                Register
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>

            </header>
            {/**
         * MAIN 
         * Uses a similar “section” approach as the landing page.
         */}
            <main className="flex-grow py-14 sm:py-20">
                <div className="mx-auto max-w-6xl px-6 lg:px-8">
                    {/* Title / Intro */}
                    <div className="mx-auto max-w-3xl text-center">
                        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            From the HinterviewGPT Blog
                        </h2>
                        <p className="mt-6 text-lg font-medium leading-8 text-gray-800">
                            Insights, updates, and tips to make the most of your interview practice.
                        </p>
                    </div>

                    {/* Blog Post Grid */}
                    <div className="mt-16 grid max-w-2xl mx-auto grid-cols-1 gap-x-8 gap-y-12 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {posts.map((post) => (
                            <article
                                key={post.id}
                                className="relative flex flex-col items-start justify-between transition-shadow hover:shadow-xl bg-white rounded-2xl border border-gray-200 p-6"
                            >
                                <div className="flex items-center gap-x-4 text-xs text-gray-500">
                                    <time dateTime={post.datetime}>{post.date}</time>
                                </div>
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900">
                                    <Link to={`/blog/${post.id}`}>{post.title}</Link>
                                </h3>
                                <p className="mt-4 line-clamp-3 text-sm leading-6 text-gray-600">
                                    {post.description}
                                </p>
                                {/* Optional: If you want a "Read more" link or button */}
                                <div className="mt-4">
                                    <Link
                                        to={`/blog/${post.id}`}
                                        className="text-indigo-600 font-medium hover:text-indigo-500"
                                    >
                                        Read more →
                                    </Link>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </main>

            {/**
         * FOOTER 
         * Matches the style in the landing page: 
         * white background, subtle top border, centered text
         */}
            <footer className="bg-white border-t border-gray-300 mt-auto text-gray-600">
                <div className="mx-auto max-w-7xl py-6 px-6 lg:px-8 flex flex-col sm:flex-row items-center justify-between text-sm">
                    <p className="text-center sm:text-left">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div className="mt-4 sm:mt-0">
                        <a href="/privacy" className="hover:text-indigo-500 transition ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="hover:text-indigo-500 transition ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Blog;
