import { useEffect, useState } from 'react';
import { auth, db } from './config/firebase';
import { User } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

const faqs = [
    {
        question: "My free trial has expired. How do I get sign up for a subscription?",
        answer:
            "If your free trial has expired, you can sign up for a subscription by visiting <a href='/account' class='text-indigo-600 hover:text-indigo-900'>account settings</a> and clicking the 'Activate Subscription' button.  Once you have signed up to a plan, you will then be able to continue to use HinterviewGPT.",
    },
    {
        question: "How do I cancel my subscription?",
        answer: "To cancel your subscription, visit <a href='/account' class='text-indigo-600 hover:text-indigo-900'>account settings</a> and click the 'Cancel Subscription' button.  You will then be taken to the Stripe customer portal where you can cancel your subscription. If you have any questions or need assistance, please contact our support team at <a href='mailto:support@hinterviewgpt.com' class='text-indigo-600 hover:text-indigo-900'>support@hinterviewgpt.com</a>.",
    },
    {
        question: 'How do I change subscription plans?',
        answer: 'To change your subscription, visit <a href="/account" class="text-indigo-600 hover:text-indigo-900">account settings</a> and click the "Change Subscription" button. You will then be taken to the Stripe customer portal where you can change your subscription plan. If you have any questions or need assistance, please contact our support team at <a href="mailto:support@hinterviewgpt.com" class="text-indigo-600 hover:text-indigo-900">support@hinterviewgpt.com</a>.',
    },
    {
        question: "How do I create a question to practice?",
        answer:
            "To create a question for practice, begin by clicking the 'Add Question' link in the navbar. You will be presented with two options: 'Manually Add Question' and 'HinterviewGPT Generation.' If you choose 'Manually Add Question,' you can enter the question you wish to practice manually. This includes providing a title, selecting the answer type, and adding relevant tags. Alternatively, if you select 'HinterviewGPT Generation,' you can describe a topic you'd like to practice, and HinterviewGPT will generate a realistic question tailored to your needs. This allows you to create a customized practice experience that aligns with your goals.",
    },
    {
        question: "How do I submit a solution to a question?",
        answer:
            "When you are practicing a question and have crafted a solution you are satisfied with on the whiteboard, simply click the 'Submit' button. HinterviewGPT will then evaluate your solution. You can view all of your submitted solutions for a question by navigating to the 'Submissions' tab, where each attempt is recorded for your review and improvement.",
    },
];

const Help = () => {
    const [user, setUser] = useState<User | null>(null);
    const [userSet, setUserSet] = useState(false);
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState<{ type: 'success' | 'error' | '', message: string }>({ type: '', message: '' });

    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
                setUserSet(true);
            } else {
                setUser(null);
                setUserSet(false);
                navigate('/auth');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        document.title = 'Help Center | HinterviewGPT';
    }, []);

    const isValidEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!isValidEmail(email)) {
            setStatus({ type: 'error', message: 'Please enter a valid email address.' });
            return;
        }
        if (subject.trim() === '') {
            setStatus({ type: 'error', message: 'Please enter a subject.' });
            return;
        }
        if (message.trim() === '') {
            setStatus({ type: 'error', message: 'Please enter a message.' });
            return;
        }

        if (user) {
            try {
                const helpCollectionRef = collection(db, "help");
                await addDoc(helpCollectionRef, {
                    userId: user.uid,
                    email,
                    subject,
                    message,
                    resolved: false,
                    createdAt: new Date()
                });
                setStatus({ type: 'success', message: 'Message sent successfully!' });
                setEmail('');
                setSubject('');
                setMessage('');
            } catch (error) {
                console.error("Error adding document: ", error);
                setStatus({ type: 'error', message: 'There was an error sending your message. Please try again.' });
            }
        } else {
            setStatus({ type: 'error', message: 'User not authenticated' });
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-white ">
            {/* Navbar */}
            <Navbar currentNav="Help" />

            {/* Main content */}
            <div className="flex-grow bg-white mb-12">
                <div className="mx-auto max-w-7xl px-6 py-14 sm:py-22 lg:px-8 lg:py-22">
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Help Center</h2>
                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                            {faqs.map((faq) => (
                                <Disclosure key={faq.question} as="div" className="pt-6">
                                    <dt>
                                        <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                            <span className="text-base font-semibold leading-7">{faq.question}</span>
                                            <span className="ml-6 flex h-7 items-center">
                                                <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                                                <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                                            </span>
                                        </DisclosureButton>
                                    </dt>
                                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                                        <p className="text-base leading-7 text-gray-600" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                    </DisclosurePanel>
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>

                <div className="mx-auto max-w-7xl px-6">
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                        <h2 className="text-xl font-bold leading-10 tracking-tight text-gray-900">Still can't find what you're looking for?</h2>
                    </div>
                </div>
                <div className="bg-white p-8 rounded-lg border w-full max-w-3xl mx-auto mt-8">
                    <h2 className="text-2xl font-bold mb-2 text-gray-800">Contact Us</h2>
                    <p className="text-gray-600 mb-6">If you are experiencing technical issues or have any other questions, please reach out to us. We will respond as soon as possible.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Your email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="subject" className="block text-gray-700 text-sm font-bold mb-2">Subject</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Your message</label>
                            <textarea
                                id="message"
                                name="message"
                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="flex items-center justify-between">
                            <button type="submit"
                                className="flex items-center rounded-md border border-gray-300 bg-gray-200 px-3 py-2 text-center text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                    {status.type && (
                        <div className={`mt-4 p-4 rounded ${status.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                            {status.type === 'success' ? (
                                <svg className="w-6 h-6 inline-block mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                            ) : (
                                <svg className="w-6 h-6 inline-block mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            )}
                            {status.message}
                        </div>
                    )}
                </div>
            </div>

            <div className="bg-white border-t border-gray-200 mt-auto">
                <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8 flex justify-between items-center text-sm">
                    <p className="text-gray-500">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div>
                        <a href="/privacy" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Privacy Policy
                        </a>
                        <a href="/tos" className="text-indigo-600 hover:text-indigo-900 ml-4">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Help;
