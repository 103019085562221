import React, { useState, useCallback, useEffect, useRef } from 'react';
import LanguageSelector from './LanguageSelector';
import ReactQuill from 'react-quill';
import { IconChalkboard } from '@tabler/icons-react';
import Editor, { Monaco } from "@monaco-editor/react";
import { IconMaximize, IconMinimize, IconChevronUp, IconChevronDown, IconArrowBackUp } from '@tabler/icons-react';
import katex from "katex";
import "katex/dist/katex.min.css";
import { Tooltip } from '@mui/material';
import './App.css';


window.katex = katex;


interface QuestionEditorProps {
    selectedLanguage: string;
    onLanguageChange: (language: string) => void;
    handleCodeRefresh: () => void;
    handleSaveCode: () => void;
    // setCode: (code: string) => void;
    // code: string;
    codeAnswer: string;
    setCodeAnswer: (code: string) => void;
    textAnswer: string;
    setTextAnswer: (code: string) => void;
    answerType: string;
    handleToggleTheme: () => void;
    isDarkMode: boolean;
    isEditorMaximized: boolean;
    isEditorFolded: boolean;
    setIsEditorMaximized: (value: boolean) => void;
    minimizeEditorPane: () => void;
    unminimizeEditorPane: () => void;
}

const QuestionEditor: React.FC<QuestionEditorProps> = ({
    selectedLanguage,
    onLanguageChange,
    handleCodeRefresh,
    handleSaveCode,
    codeAnswer,
    setCodeAnswer,
    textAnswer,
    setTextAnswer,
    answerType,
    handleToggleTheme,
    isDarkMode,
    isEditorMaximized,
    isEditorFolded,
    setIsEditorMaximized,
    minimizeEditorPane,
    unminimizeEditorPane
}) => {


    const [isActive, setIsActive] = useState(false); // Track if either section is active
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const originalError = console.error;
        console.error = (...args) => {
          if (
            typeof args[0] === 'string' &&
            args[0].includes('ResizeObserver loop completed')
          ) {
            return; // ignore this warning
          }
          originalError(...args);
        };
        return () => {
          console.error = originalError;
        };
      }, []);
      

    // Handle clicks outside the container
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsActive(false); // Disable both borders if clicked outside
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const handleEditorChange = (newValue: any) => {
        if (answerType === 'Code') {
            // Due to strange bug when switching from Text answer question to Code answer question
            if (newValue !== '<p><br></p>') {
                setCodeAnswer(newValue);
            }
        } else if (answerType === 'Text') {
            setTextAnswer(newValue || '');
        }
    };

    const isAnswerEmpty = () => {
        if (answerType === 'Code') {
            return codeAnswer.trim() === '';
        } else if (answerType === 'Text') {
            return textAnswer.trim() === '<p><br></p>' || textAnswer.trim() === '';
        }
        return true;
    };


    const containerStyle = {
        height: '100%', // Set height to 100% to fill up all available space
        border: '1px solid #ccc', // Example border for visualization
    };

    const handleMaximizeClick = () => {
        setIsEditorMaximized(true);
    };
    const handleMinimizeClick = () => {
        setIsEditorMaximized(false);
    };

    return (
        <div
            ref={containerRef}
            className=" topRightPaneStyle " style={{ paddingTop: '0px', paddingRight: '7px', paddingLeft: '4px', paddingBottom: '4px', backgroundColor: '#ebebeb' }}>



            <div
                onClick={() => setIsActive(true)}
                className="py-1 px-1 font-medium text-sm "
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fafafa',
                    borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                    borderLeft: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
                    borderRight: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
                    borderTop: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '3px', paddingTop: '4px', backgroundColor: '#fafafa' }}>
                    <div className="font-medium text-sm" style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', }}>
                        <IconChalkboard className="mr-1 ml-1 inline-block text-blue-500" style={{ width: '22px', height: '22px' }} /> Whiteboard
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>

                    {answerType === 'Code' ? (

                        <div style={{ display: 'flex', alignItems: 'center' }} className='mr-1'>

                            <LanguageSelector
                                selectedLanguage={selectedLanguage}
                                onLanguageChange={onLanguageChange}
                            />
                        </div>
                    ) : answerType === 'Text' ? (
                        <div >

                        </div>
                    ) : null}


                    <Tooltip title={isAnswerEmpty() ? "Whiteboard cannot be empty" : ""} disableHoverListener={!isAnswerEmpty()}>
                            <button
                                onClick={handleSaveCode}
                                className={`mr-1 ml-2 text-white px-4 py-1 text-sm rounded-md ${isAnswerEmpty() ? "bg-green-500 cursor-not-allowed" : "bg-green-500 hover:bg-green-600"}`}
                                disabled={isAnswerEmpty()}
                            >
                                Submit
                            </button>
                    </Tooltip>
                    <div
                        className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center  ml-1"
                        onClick={() => { setCodeAnswer(''); setTextAnswer(''); }}
                    >
                        <Tooltip title="Clear" >
                            <IconArrowBackUp className="w-5 h-5 text-gray-500" />
                        </Tooltip>
                    </div>
                    {!isEditorMaximized && (
                        <>
                            {isEditorFolded ? (
                                <div
                                    className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1 ml-1"
                                    onClick={unminimizeEditorPane}
                                >
                                    <Tooltip title="Unfold" >
                                        <IconChevronDown className="w-5 h-5 text-gray-500" />
                                    </Tooltip>
                                </div>
                            ) : (
                                <div
                                    className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1 ml-1"
                                    onClick={minimizeEditorPane}
                                >
                                    <Tooltip title="Fold">
                                        <IconChevronUp className="w-5 h-5 text-gray-500" />
                                    </Tooltip>
                                </div>
                            )}
                        </>
                    )}

                    {!isEditorMaximized && (

                        <div
                            className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center"
                            onClick={handleMaximizeClick} >
                            <Tooltip title="Maximize" >
                                <IconMaximize className="w-5 h-5 text-gray-500 " />
                            </Tooltip>

                        </div>
                    )}
                    {isEditorMaximized && (
                        <div
                            className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center"
                            onClick={handleMinimizeClick}>
                            <Tooltip title="Minimize" >
                                <IconMinimize className="w-5 h-5 text-gray-500" />
                            </Tooltip>
                        </div>
                    )}

                </div>
            </div>

            {answerType === 'Text' ? (
                <div
                    onClick={() => setIsActive(true)}
                    style={{
                        height: '100%', overflow: 'hidden', backgroundColor: 'white'
                        , borderBottomLeftRadius: '10px', paddingBottom: '40px', borderBottomRightRadius: '10px',
                        borderLeft: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
                        borderRight: `1px solid ${isActive ? '#dadada' : 'transparent'}`,
                        borderBottom: `1px solid ${isActive ? '#dadada' : 'transparent'}`
                    }}>
                    <ReactQuill
                        theme="snow"
                        modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ["bold", "italic", "underline", "strike", "blockquote", "code-block"],

                                [{ list: "ordered" }, { list: "bullet" }], [

                                    { indent: "-1" },
                                    { indent: "+1" },
                                    { align: [] }
                                ],
                                [{ script: 'sub' }, { script: 'super' }], // Subscript and Superscript
                                [{ 'formula': true }]
                            ]
                        }}
                        value={textAnswer}
                        onChange={handleEditorChange}
                        style={{ height: '100%', backgroundColor: 'white' }}
                        className="custom-quill"

                    />
                </div>


            ) : answerType === 'Code' ? (
                <div className='editor-container'
                     onClick={() => setIsActive(true)}
                    style={{
                        borderLeft: isActive ? '1px solid #dadada' : 'none',
                        borderRight: isActive ? '1px solid #dadada' : 'none',
                        borderBottom: isActive ? '1px solid #dadada' : 'none',
                        borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'
                    }}>
                    <Editor
                        width="100%"
                        language={selectedLanguage}
                        theme="light"
                        value={codeAnswer}
                        onChange={handleEditorChange}
                        options={{
                            minimap: {
                                enabled: false,
                            },
                            selectOnLineNumbers: true,

                            automaticLayout: true,
                            scrollBeyondLastLine: false,
                            padding: {
                                top: 10,
                                bottom: 20,
                            }
                        }}
                    />
                </div>
            ) : null /* You can handle other cases if necessary */}



        </div >


    );
};

export default QuestionEditor;