import { useEffect, useState } from 'react';
import { auth } from './config/firebase';
import { User } from "firebase/auth";
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logoLato from './assets/logo_arimo_v3.png'

const navigation = [
  { name: 'How it works', href: '/#how-it-works' },
  { name: 'Pricing', href: '/#pricing' },
  { name: 'FAQ', href: '/#faq' },
  { name: 'Blog', href: '/blog' },
];

const PrivacyPolicy = () => {
  const [user, setUser] = useState<User | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const SCROLL_DELTA = 10; // Adjust sensitivity here
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    function handleScroll() {
      const currentScrollY = window.pageYOffset;

      // Toggle hideHeader logic (already in your code)
      if (currentScrollY - lastScrollY > SCROLL_DELTA) {
        setHideHeader(true);
      } else if (lastScrollY - currentScrollY > 0) {
        setHideHeader(false);
      }
      lastScrollY = currentScrollY;

      // NEW: Toggle the navbar background
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Update document title
  useEffect(() => {
    document.title = 'Privacy Policy | HinterviewGPT';
  }, []);

  // Listen for auth changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser ? authUser : null);
    });
    return () => unsubscribe();
  }, []);

  // Hide header on scroll similar to BlogPost component
  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY - lastScrollY > 10) {
        setHideHeader(true);
      } else if (lastScrollY - currentScrollY > 0) {
        setHideHeader(false);
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* HEADER */}
      <header
        className={`
                        sticky top-0 z-50 transition-all duration-300
                        ${hasScrolled ? 'bg-white/50 backdrop-blur-sm' : 'bg-gray-50'}
                        
                      `}
      >
        <nav aria-label="Global" className="mx-auto
      flex
      max-w-7xl
      items-center
      justify-between
      px-4
      py-3  
      lg:px-8">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src={logoLato}
                className="h-8 w-auto"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm/6 font-semibold text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
            {user ? (
              <a
                href="/activity"
                className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
              >
                Dashboard
              </a>
            ) : (
              <>
                <a
                  href="/auth?type=login"
                  className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
                >
                  Log In
                </a>
                <a
                  href="/auth?type=register"
                  className="ml-4 rounded-md border border-indigo-600 px-3 py-1.5 text-md font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Register
                </a>
              </>
            )}
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-10" />
          <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src={logoLato}
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  {user ? (
                    <a
                      href="/activity"
                      className="rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500 transition duration-200"
                    >
                      Dashboard
                    </a>
                  ) : (
                    <>
                      <a
                        href="/auth?type=login"
                        className="block w-full rounded-md bg-indigo-600 px-4 py-2 text-base font-semibold text-white hover:bg-indigo-500 transition duration-200"
                      >
                        Log In
                      </a>
                      <a
                        href="/auth?type=register"
                        className="mt-3 block w-full rounded-md border border-indigo-600 px-4 py-2 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white transition duration-200"
                      >
                        Register
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>

      </header>
      {/* MAIN CONTENT */}
      <main className="flex-grow py-8 sm:py-12">
        <article className="mx-auto max-w-5xl px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-6">Privacy Policy</h1>

          <div className="prose max-w-none text-gray-800">
            <section>
              <h2>Introduction</h2>
              <p>
                Welcome to HinterviewGPT. We are committed to protecting your privacy and ensuring that your personal information is handled safely and responsibly. This Privacy Policy outlines how we collect, use, and protect your information.
              </p>
            </section>

            <section>
              <h2>Data Collection and Usage</h2>
              <p>
                HinterviewGPT collects and saves the following data to enhance your experience:
              </p>
              <ul>
                <li>Questions added through HinterviewGPT generation or manual generation.</li>
                <li>Generated solutions and user-created solutions that are submitted.</li>
                <li>HinterviewGPT's evaluation of submitted solutions.</li>
                <li>Usage statistics, including the number of questions added and solutions submitted.</li>
                <li>Data related to account login and authentication.</li>

              </ul>
              <p>
                You have the ability to delete questions that you have added and also the solutions that you have submitted.
              </p>
            </section>

            <section>
              <h2>Chat Privacy</h2>
              <p>
                Please note that nothing you share in the HinterviewGPT chat is ever saved. This ensures that your conversations remain private.
              </p>
            </section>

            <section>
              <h2>OpenAI Privacy</h2>
              <p>
                HinterviewGPT leverages the OpenAI API  to perform various tasks including
                <ul>
                  <li>Question generation.</li>
                  <li>Solution generation.</li>
                  <li>Solution evaluation.</li>
                  <li>HinterviewGPT chat.</li>
                </ul>
                When calling the OpenAI API, the data that is passed is subject to{' '}
                <a
                  href="https://platform.openai.com/docs/models/how-we-use-your-data#how-we-use-your-data"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-indigo-600 hover:text-indigo-900 transition-colors"
                >
                  OpenAI's privacy policy
                </a>.
              </p>
            </section>

            <section>
              <h2>Data Security</h2>
              <p>
                We take appropriate measures to protect your data from unauthorized access, alteration, or destruction. However, no security measures are perfect or impenetrable, so we cannot guarantee absolute security.
              </p>
            </section>

            <section>
              <h2>Your Rights</h2>
              <p>
                You have the right to access, correct, or delete any personal data we hold about you. If you wish to exercise these rights, please contact us at support@hinterviewgpt.com.
              </p>
            </section>

            <section>
              <h2>Changes to This Policy</h2>
              <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. Please review this policy periodically to stay informed about how we are protecting your information.
              </p>
            </section>

            <section>
              <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy, please contact us at support@hinterviewgpt.com.
              </p>
            </section>
          </div>
        </article>
      </main>

      {/* FOOTER */}
      <footer className="bg-white border-t border-gray-300 mt-auto text-gray-600">
        <div className="mx-auto max-w-7xl py-6 px-6 lg:px-8 flex flex-col sm:flex-row items-center justify-between text-sm">
          <p className="text-center sm:text-left">
            © 2024 HinterviewGPT. All rights reserved.
          </p>
          <div className="mt-4 sm:mt-0">
            <a href="/privacy" className="hover:text-indigo-500 transition ml-4">
              Privacy Policy
            </a>
            <a href="/tos" className="hover:text-indigo-500 transition ml-4">
              Terms of Service
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
