
interface PlanModelLimits {
  [plan: string]: {
    [modelName: string]: number;
  };
}

export const planModelLimits: PlanModelLimits = {
  // Basic: {
  //   "gpt-4o-mini-2024-07-18": 500, // Example limit for Basic plan
  //   "gpt-4o-2024-08-06": 800, // Example limit for Basic plan
  // },
  None: {
    "gpt-4o-mini-2024-07-18": 50, // Example limit for Standard plan
  },
  Basic: {
    "gpt-4o-mini-2024-07-18": 250, // Example limit for Standard plan
  },
  Standard: {
    "gpt-4o-mini-2024-07-18": 500, // Example limit for Standard plan
    "gpt-4o-2024-08-06": 250, // Example limit for Standard plan
    "o3-mini-2025-01-31": 100, // Example limit for Advanced plan
    "o1-mini-2024-09-12": 100, // Example limit for Advanced plan
  },
  Advanced: {
    "gpt-4o-mini-2024-07-18": 750, // Example limit for Advanced plan
    "gpt-4o-2024-08-06": 500, // Example limit for Advanced plan
    "o3-mini-2025-01-31": 250, // Example limit for Advanced plan
    "o1-mini-2024-09-12": 250, // Example limit for Advanced plan
  },

};

export const modelMapping = [
  {
    id: 'gpt-4o-mini-2024-07-18',
    title: 'GPT-4o mini',
    subtitle: 'Fast, affordable small model for focused tasks.',
  },
  {
    id: 'gpt-4o-2024-08-06',
    title: 'GPT-4o',
    subtitle: 'Fast, intelligent, flexible GPT model.',
  },
  {
    id: 'o3-mini-2025-01-31',
    title: 'o3-mini',
    subtitle: 'Fast, flexible, intelligent reasoning model.',
  },
  {
    id: 'o1-mini-2024-09-12',
    title: 'o1-mini',
    subtitle: 'A faster, more affordable reasoning model.',
  }
];
