import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Box, Button, Badge, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ReactMarkdown from 'react-markdown';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconEdit, IconFileDescription, IconListCheck, IconFlaskFilled, IconRefresh, IconTag, IconFileExport, IconHttpDelete, IconTrash, IconZoomQuestion } from '@tabler/icons-react';
import Editor from "@monaco-editor/react";
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png'
import DOMPurify from 'dompurify';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { IconMaximize, IconMinimize, IconCopy } from '@tabler/icons-react';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
// import 'prismjs/themes/prism-tomorrow.css'; // Or any other Prism theme
import rehypePrism from 'rehype-prism-plus'
import { visit } from 'unist-util-visit';
import Prism from 'prismjs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionDetailsAccordion from './QuestionDetailsAccordion';
import Pre from './copy';
import { SparklesIcon, InboxIcon } from '@heroicons/react/24/outline';
import { Dialog as Dialog2 } from '@headlessui/react';
import { DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid'



interface QuestionDisplayProps {
  leetCodeTitle: string;
  answerType: string;
  leetCodeQuestion: string;
  leetCodeTags: string[];
  leetCodeAnswer: string;
  fetchedCode: Array<{ code: string; aiEval: string, timestamp: string, language: string, model: string }> | null;
  questionNumber: number | null;
  isDisplayMaximized: boolean;
  setIsDisplayMaximized: (value: boolean) => void;
  genSolution: () => void;
  genAnswerLoading: boolean;
  handleExportToWhiteboard: (code: string) => void;
  createMethod: string;
  createDate: string;
  createModel: string;
}

const preprocessLaTeX = (content: string) => {
  // Replace block-level LaTeX delimiters \[ \] with $$ $$


  const blockProcessedContent = content.replace(
    /\\\[(.*?)\\\]/gs,
    (_, equation) => `$$${equation}$$`,
  );
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\((.*?)\\\)/gs,
    (_, equation) => `$${equation}$`,
  );
  return inlineProcessedContent;
};

const MarkdownViewer = ({ markdownContent }: { markdownContent: string }) => {
  const rehypePrismOptions = {
    showLineNumbers: true, // Example option
    ignoreMissing: true, // Example option
    defaultLanguage: 'c', // Example option
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[[rehypeKatex], [rehypePrism, rehypePrismOptions]]}
      components={{
        pre: (props) => <Pre {...props} />
      }}
    >
      {preprocessLaTeX(markdownContent)}
    </ReactMarkdown>
  );
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface RenderHTMLContentProps {
  content: string;
}

const renderMath = (htmlContent: string) => {
  // Define a regex pattern to find math expressions within \[...\] or \(...\)
  const mathPattern = /\\\[(.*?)\\\]|\\\((.*?)\\\)/g;

  return htmlContent.replace(mathPattern, (match, displayMath, inlineMath) => {
    try {
      // Render the math expression using KaTeX
      const renderedMath = katex.renderToString(displayMath || inlineMath, {
        throwOnError: false,
        displayMode: !!displayMath, // Display mode for \[...\]
      });
      return renderedMath;
    } catch (error) {
      console.error("Failed to render math:", error);
      return match; // Return the original string if rendering fails
    }
  });
};

const RenderHTMLContent: React.FC<RenderHTMLContentProps> = ({ content }) => {
  // Sanitize the content and render math
  const sanitizedContent = DOMPurify.sanitize(renderMath(content));

  return (
    <div
      className="custom-html-content"
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  );

};




const QuestionDisplay: React.FC<QuestionDisplayProps & { updateFetchedCode: () => void, handleDeleteCode: (idx: number) => void, genSolution: () => void }> = ({
  leetCodeTitle,
  answerType,
  leetCodeQuestion,
  leetCodeTags,
  leetCodeAnswer,
  fetchedCode,
  questionNumber,
  updateFetchedCode,
  handleDeleteCode,
  isDisplayMaximized,
  setIsDisplayMaximized,
  genSolution,
  genAnswerLoading,
  handleExportToWhiteboard,
  createMethod,
  createDate,
  createModel
}) => {
  const [activeTab, setActiveTab] = useState<string | null>('first');
  const [selectedSubmission, setSelectedSubmission] = useState<{
    code: string;
    aiEval: string;
    timestamp: string;
    language: string;
    model: string;
  } | null>(null);
  const [openCodeModal, setOpenCodeModal] = useState(false); // State for controlling the code modal
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const isLoading = !leetCodeTitle || !leetCodeQuestion || !questionNumber === null;
  const prevQuestionNumberRef = useRef(questionNumber);
  const [copied, setCopied] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false); // Accordion state
  const contentRef = useRef<HTMLDivElement>(null); // Reference for the top of the container
  const [expanded, setExpanded] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [submissionIndexToDelete, setSubmissionIndexToDelete] = useState<number | null>(null);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        setIsActive(false); // Remove the border
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded); // Update the state based on whether the accordion is expanding or collapsing
  };

  const handleOpenDeleteDialog = (index: number) => {
    setSubmissionIndexToDelete(index);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSubmissionIndexToDelete(null);
  };

  const confirmDelete = () => {
    if (submissionIndexToDelete !== null) {
      handleDeleteCode(submissionIndexToDelete);
    }
    handleCloseDeleteDialog();
  };

  const handleCopy = () => {
    if (answerType === 'Code') {
      navigator.clipboard.writeText(selectedSubmission?.code || '');
    } else {
      navigator.clipboard.writeText(selectedSubmission?.code || '');
    }
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  const handleViewCode = (submission: {
    code: string;
    aiEval: string;
    timestamp: string;
    language: string;
    model: string;
  }) => {
    const formattedTimestamp = new Date(submission.timestamp).toLocaleDateString('en-US');
    setSelectedSubmission({
      ...submission,
      timestamp: formattedTimestamp,
    });
    setOpenCodeModal(true);
  };

  const handleCloseCodeModal = () => {
    setOpenCodeModal(false);
  };

  useEffect(() => {
    // Fetch the latest code when updateFetchedCode is called
    updateFetchedCode();
  }, []); // Empty dependency array ensures this runs only on component mount

  useEffect(() => {
    if (fetchedCode) {
      setTotalSubmissions(fetchedCode.length);

      // Check if questionNumber has changed
      const prevQuestionNumber = prevQuestionNumberRef.current;

      if (questionNumber !== prevQuestionNumber) {
        // The change was triggered by questionNumber
        setActiveTab('first');
      }

      // Update the ref to the current questionNumber
      prevQuestionNumberRef.current = questionNumber;
    }
  }, [fetchedCode, questionNumber]);

  const tabContainerStyle = {
    marginBottom: '0px',
    backgroundColor: 'white',
  };


  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleMaximizeClick = () => {
    setIsDisplayMaximized(true);
  };
  const handleMinimizeClick = () => {
    setIsDisplayMaximized(false);
  };

  useEffect(() => {
    // Scroll to top on component mount

    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }

    setAccordionExpanded(false);
    setExpanded(false);
  }, [questionNumber]);



  return (


    <div className='topRightPaneStyle ' style={{ paddingTop: '0px', paddingRight: '4px', paddingLeft: '7px', paddingBottom: '2px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>

      <Box
        ref={boxRef}
        onClick={() => setIsActive(true)}
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          // border: '1px solid #dadada',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
          border: `1px solid ${isActive ? '#dadada' : 'transparent'}`
        }}
      >

        <div style={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor: '#fafafa', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
          <div className=" ml-2 flex justify-between items-center">
            <nav className="-mb-px flex space-x-4 " aria-label="Tabs">
              <button
                onClick={() => handleTabChange('first')}
                className={`whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm ${activeTab === 'first'
                  ? 'border-black text-black'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } flex items-center`}
              >
                <IconFileDescription
                  className={`mr-1 small-icon ${activeTab === 'first' ? 'text-blue-500' : 'text-blue-300'}`}
                />
                Description
              </button>
              <button
                onClick={() => handleTabChange('second')}
                className={`whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm ${activeTab === 'second'
                  ? 'border-black text-black'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } flex items-center`}
              >
                <IconListCheck
                  className={`mr-1 small-icon ${activeTab === 'second' ? 'text-yellow-500' : 'text-yellow-300'}`}
                />
                Submissions
                <span
                  className={classNames(
                    'bg-gray-200 text-gray-900 ml-2 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
                  )}
                >
                  {totalSubmissions}
                </span>
              </button>
              <button
                onClick={() => handleTabChange('third')}
                className={`whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm ${activeTab === 'third'
                  ? 'border-black text-black'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } flex items-center`}
              >
                <IconFlaskFilled
                  className={`mr-1 small-icon ${activeTab === 'third' ? 'text-green-500' : 'text-green-300'}`}
                />
                Solution
              </button>

            </nav>

            {!isDisplayMaximized && (
              <div
                className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1 ml-1"
                onClick={handleMaximizeClick}
              >
                <Tooltip title="Maximize">
                  <IconMaximize className="w-5 h-5 text-gray-500 " />
                </Tooltip>
              </div>
            )}
            {isDisplayMaximized && (
              <div
                className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 p-1 rounded flex items-center justify-center mr-1 ml-1"
                onClick={handleMinimizeClick}
              >
                <Tooltip title="Minimize" >
                  <IconMinimize className=" w-5 h-5 text-gray-500" />
                </Tooltip>
              </div>
            )}
          </div>
        </div>


        <Box p={3} flex={1} style={{ overflowY: 'auto' }} ref={contentRef}>
          {activeTab === 'first' && (
            <Box>
              {isLoading ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="300px">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <div className="text-2xl font-bold leading-6 text-gray-900 mb-4">
                    <div className="text-2xl font-bold leading-8 text-gray-900 mb-4">
                      {questionNumber !== null ? `${questionNumber + 1}.  ` : ''}{leetCodeTitle}
                    </div>
                  </div>
                  <div className="mb-4">
                    {leetCodeTags.map((tag, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center rounded-xl bg-gray-500/10 px-2 py-1 text-xs font-medium mr-1 text-gray-900"
                      >
                        <IconTag className='w-3.5 h-3.5 mr-1 font-bold' />{tag}
                      </span>
                    ))}
                  </div>
                  <RenderHTMLContent content={leetCodeQuestion} />
                  <QuestionDetailsAccordion
                    createDate={createDate}
                    createMethod={createMethod}
                    createModel={createModel}
                    isExpanded={expanded}
                    setIsExpanded={setExpanded}
                  />
                </>
              )}
            </Box>
          )}

          {activeTab === 'second' && (
            <Box>
              {fetchedCode && fetchedCode.length > 0 ? (
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                          Date Submitted
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                          Type
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                          Eval Model
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-sm font-semibold text-gray-900">
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {fetchedCode.map((submission, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 font-bold">
                            <a href="#" onClick={() => handleViewCode(submission)} className="text-indigo-600 hover:text-indigo-900">
                              {new Date(submission.timestamp).toLocaleDateString('en-US')}
                            </a>
                          </td>
                          <td className="py-2 pl-2 pr-2 text-sm font-medium text-gray-900">
                            <span
                              key={index}
                              className="inline-flex items-center rounded-md bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
                            >
                              {submission.language}
                            </span>
                          </td>
                          <td className="py-2 pl-2 pr-2 text-sm font-medium text-gray-900">
                            <span
                              key={index}
                              className="inline-flex items-center rounded-md bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
                            >
                              {submission.model ? submission.model : "Unknown"}
                            </span>
                          </td>
                          <td className="py-2 pl-2 pr-2 text-sm font-medium text-gray-900">

                            <Tooltip title="Delete Submission">

                              <button
                                onClick={() => handleOpenDeleteDialog(index)}
                                className="text-indigo-300 hover:text-indigo-600"
                              >
                                <IconTrash />
                              </button>
                            </Tooltip>


                          </td>
                          <td className="py-2 pl-2 pr-2 text-sm font-medium text-gray-900">
                            <Tooltip title="Export to Whiteboard">

                              <button
                                className="text-indigo-300 hover:text-indigo-600"
                                onClick={() => handleExportToWhiteboard(submission.code)}
                              >
                                <IconFileExport />
                              </button>
                            </Tooltip>


                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

              ) : (
                <div className="flex flex-col items-center justify-center h-full text-center pt-16 p-4">
                  <InboxIcon className="h-12 w-12 text-gray-400 mb-4" />
                  <h3 className="text-xl font-semibold text-gray-700">No Submissions</h3>
                  <p className="text-gray-500 mt-2">
                  Get started by creating a submission in the editor and clicking the submit button.
                  </p>
                </div>

              )}
            </Box>

          )}
          {activeTab === 'third' && (
            <Box>
              <div className="flex justify-between items-center mb-2" style={isDisplayMaximized ? { padding: '0 80px' } : {}}>
                <div className="text-2xl font-bold leading-8 text-gray-900">
                  Solution
                </div>
                <Tooltip title="(re)generate solution">
                  <button
                    onClick={genSolution}
                    className="cursor-pointer py-1.5 px-3.5 bg-indigo-500 hover:bg-indigo-600 text-white rounded-md text-base focus:outline-none focus:ring-2 focus:ring-indigo-300 flex items-center"
                  >
                    {genAnswerLoading && (
                      <svg
                        className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8z"
                        ></path>
                      </svg>
                    )}
                    Generate
                  </button>

                </Tooltip>
              </div>
              <hr className="mb-4" style={isDisplayMaximized ? { padding: '0 80px' } : {}} />

              {genAnswerLoading ? (
                <div className="animate-pulse space-y-4">
                  <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                  <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  <div className="h-4 bg-gray-200 rounded w-4/5"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                  <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  <div className="h-4 bg-gray-200 rounded w-4/5"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                  <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                  <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                </div>
              ) : leetCodeAnswer ? (
                <div className="ai-message-content-2" style={isDisplayMaximized ? { padding: '0 80px' } : {}}>
                  <MarkdownViewer markdownContent={leetCodeAnswer} />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-center pt-20 p-4">
                  <SparklesIcon className="h-12 w-12 text-gray-400 mb-4" />
                  <h3 className="text-xl font-semibold text-gray-700">Your Solution Awaits</h3>
                  <p className="text-gray-500 mt-2">
                    Click the button above to generate a solution to this question
                  </p>
                </div>
              )}
            </Box>
          )}

        </Box>
      </Box>

      <Dialog
        open={openCodeModal}
        onClose={handleCloseCodeModal}
        aria-labelledby="code-modal-title"
        aria-describedby="code-modal-description"
        PaperProps={{
          style: {
            maxWidth: '95%',
            width: '95%',
            height: '95%',
            position: 'relative',
            borderRadius: '12px'
          },
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={handleCloseCodeModal}
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            zIndex: 2, // Ensure it's above other elements
          }}
        >
          <CloseIcon style={{ fontSize: '20px' }} />
        </IconButton>

        <DialogContent
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px', paddingBottom: '0' }}
        >
          {/* Left Half: Code Submission */}
          <div
            style={{
              flex: 1,
              paddingRight: '0px',
              overflowX: 'auto', // Allow horizontal scroll

              overflowY: 'hidden',
              borderRight: '1px solid #ccc',
              boxSizing: 'border-box',
            }}
          >
            <div
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '5px',
                  marginLeft: '0px',
                  marginBottom: '0px',
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: '0',
                    display: 'inline-block',
                    fontWeight: 'bold',
                  }}
                >
                  Submission
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  {selectedSubmission?.timestamp && (
                    <span style={{ marginRight: '8px' }}>
                      {new Date(selectedSubmission.timestamp).toLocaleDateString('en-US')}
                    </span>
                  )}
                  <div
                    style={{
                      borderLeft: '1px solid #ccc',
                      height: '24px',
                      marginRight: '8px',
                    }}
                  ></div>
                  <Tooltip title={copied ? 'Copied' : 'Copy Submission'}>
                    <IconCopy onClick={handleCopy} className="cursor-pointer w-5 h-5" />
                  </Tooltip>
                </div>
              </div>
              <hr />
            </div>
            {answerType === 'Code' ? (
              <Editor
                language={selectedSubmission?.language}
                theme="light"
                value={selectedSubmission?.code || ''}
                options={{
                  minimap: {
                    enabled: false,
                  },
                  selectOnLineNumbers: true,
                  automaticLayout: true,
                  scrollBeyondLastLine: false,
                  padding: {
                    top: 15,
                    bottom: 55,
                  },
                  readOnly: true,
                  wordWrap: 'off', // Disable word wrap to allow horizontal scroll
                  scrollBeyondLastColumn: 2, // Allow scrolling past the last character a bit
                }}
                height="100%"
              />

            ) : (
              <div style={{ padding: '15px', paddingBottom: '55px', overflowY: 'auto', height: '100%' }}>
                <RenderHTMLContent content={selectedSubmission?.code || ''} />
              </div>
            )}
          </div>

          {/* Right Half: AI Evaluation */}
          <div
            style={{
              flex: 1,
              paddingLeft: '8px',
              overflowY: 'hidden',
              position: 'relative',
              boxSizing: 'border-box',
              overflowX: 'auto',
            }}
          >
            <div
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginLeft: '0px',
                  marginBottom: '0px',
                  padding: '5px',
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    marginBottom: '0',
                    display: 'inline-block',
                    fontWeight: 'bold',
                  }}
                >
                  Evaluation
                </Typography>
                <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-1">
                  {selectedSubmission?.model}
                </span>
              </div>
            </div>
            <hr />
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100% - 50px)', padding: '0px 10px 10px 10px' }}>
              <Typography className="ai-message-2" variant="body1">
                <div
                  className="ai-message-content-2"
                  style={{ marginLeft: '5px', alignSelf: 'flex-start' }}
                >
                  <MarkdownViewer markdownContent={selectedSubmission?.aiEval || 'No AI Evaluation available.'} />
                </div>
              </Typography>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Transition show={deleteDialogOpen} as={Fragment}>
        <Dialog2 className="relative z-10" onClose={handleCloseDeleteDialog}>
          {/* Overlay transitions */}
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/* Panel transitions */}
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Delete submission
                        </DialogTitle>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to delete this submission?
                            This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {/* Confirm Delete */}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={confirmDelete}
                    >
                      Delete
                    </button>
                    {/* Cancel */}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={handleCloseDeleteDialog}
                      data-autofocus
                    >
                      Cancel
                    </button>
                  </div>
                </DialogPanel>
              </Transition.Child>
            </div>
          </div>
        </Dialog2>
      </Transition>




    </div>
  );
};

export default QuestionDisplay;