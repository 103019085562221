import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logoText from './assets/logo_text.png'
import { Dialog, Tab, DialogPanel } from '@headlessui/react';
import { User } from "firebase/auth";
import { auth } from './config/firebase';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png';
import ReactMarkdown from 'react-markdown';
import logoLato from './assets/logo_arimo_v3.png'
import { Bars3Icon, XMarkIcon, MinusSmallIcon, PlusSmallIcon, PlusIcon } from '@heroicons/react/24/outline';


// Define the type for a blog post
type Post = {
    id: number;
    title: string;
    href: string;
    description: string;
    imageUrl: string;
    date: string;
    datetime: string;
    content: string;
    category: {
        title: string;
        href: string;
    };
};

const navigation = [
    { name: 'How it works', href: '/#how-it-works' },
    { name: 'Pricing', href: '/#pricing' },
    { name: 'FAQ', href: '/#faq' },
    { name: 'Blog', href: '/blog' },
];

const posts: Post[] = [
    {
        id: 1,
        title: 'The Advantages of Hint-based Interview Prep',
        href: '#',
        description: 'Investigating why hint-based interview prep is a better way to prepare for technical interviews.',
        imageUrl: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Sept 17, 2024',
        datetime: '2024-09-17',
        content: `
        <p>Ever found yourself staring at a complex coding problem for hours, feeling the frustration mount as every attempted solution falls flat? You’re not alone. Imagine this scenario: it’s late at night, and you’re diligently preparing for an upcoming technical interview. You tackle a challenging practice problem, and after several unsuccessful attempts, you hit a wall. Frustrated and out of ideas, you cave in and search for the answer online. Suddenly, you’re faced with a solution that makes sense only after hours of piecing it together. Sound familiar? We've all been there.</p>

<h2>The Struggle with Traditional Study Methods</h2>

<p>Traditional study methods often encourage us to seek out answers directly when we encounter difficult problems. While this might provide immediate relief, it can hinder the deep understanding necessary for mastering complex concepts. Simply looking up answers can lead to passive learning, where information is absorbed without truly grasping the underlying principles.</p>

<h2>Why Hint-Based Preparation Makes a Difference</h2>

<p>Hint-based interview preparation offers a more effective alternative. Instead of jumping straight to the solution, hints guide you toward the answer, encouraging active problem-solving and critical thinking. This method fosters a deeper comprehension of the material and enhances your ability to tackle similar challenges in the future.</p>

<h3>1. Enhances Problem-Solving Skills</h3>

<p>Hints stimulate your mind to think creatively and explore different approaches. According to a study published in the <em>Journal of Educational Psychology</em>, students who received hints during problem-solving tasks demonstrated higher levels of understanding and retention compared to those who were simply provided with answers (VanLehn, 2011).</p>

<h3>2. Encourages Active Learning</h3>

<p>Active learning involves engaging with the material, asking questions, and seeking out solutions independently. Research from <em>Active Learning in Higher Education</em> highlights that students who engage in active learning strategies, such as hint-based studying, perform better academically and retain information longer than those who rely on passive methods (Freeman et al., 2014).</p>

<h3>3. Reduces Cognitive Overload</h3>

<p>When you encounter a difficult problem, the immediate impulse might be to find the answer to alleviate the frustration. However, this can lead to cognitive overload as you try to process and understand the solution without having worked through the problem yourself. Hint-based approaches break down the problem into manageable steps, reducing the mental strain and making the learning process more efficient (Sweller, 2011).</p>

<h3>4. Builds Confidence and Independence</h3>

<p>By relying on hints rather than direct answers, you develop the confidence to tackle problems on your own. This independence is crucial during interviews, where the ability to navigate challenges without external assistance sets you apart from other candidates. A study in the <em>Journal of Applied Psychology</em> found that individuals who practiced with hints exhibited greater self-efficacy and problem-solving abilities in high-pressure situations (Bandura, 1997).</p>

<h2>Conclusion</h2>

<p>Preparing for interviews is as much about developing problem-solving skills as it is about mastering specific answers. Hint-based interview prep offers a balanced approach that fosters deep learning, reduces frustration, and builds the confidence needed to excel in high-pressure environments. By shifting from answer-centric methods to hint-guided strategies, you can transform your study sessions from moments of overwhelm to opportunities for growth and mastery.</p>

<h3>References</h3>

<ul>
    <li>Bandura, A. (1997). <em>Self-efficacy: The exercise of control</em>. New York: W.H. Freeman.</li>
    <li>Freeman, S., Eddy, S. L., McDonough, M., Smith, M. K., Okoroafor, N., Jordt, H., &amp; Wenderoth, M. P. (2014). Active learning increases student performance in science, engineering, and mathematics. <em>Proceedings of the National Academy of Sciences</em>, 111(23), 8410-8415.</li>
    <li>Sweller, J. (2011). Cognitive load theory. <em>Psychology of Learning and Motivation</em>, 55, 37-76.</li>
    <li>VanLehn, K. (2011). The relative effectiveness of human tutoring, intelligent tutoring systems, and other tutoring systems. <em>Educational Psychologist</em>, 46(4), 197-221.</li>
</ul>

        `,
        category: { title: 'Interview Prep', href: '#' },
    },
    // Add more posts as needed...
];

const BlogPost = () => {
    const { postId } = useParams(); // Extract postId from the URL
    const [post, setPost] = useState<Post | null>(null); // Post can be either a Post object or null
    const [user, setUser] = useState<User | null>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [hideHeader, setHideHeader] = useState(false);
    const SCROLL_DELTA = 10; // Adjust sensitivity here
    const [hasScrolled, setHasScrolled] = useState(false);


    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        function handleScroll() {
            const currentScrollY = window.pageYOffset;

            // Toggle hideHeader logic (already in your code)
            if (currentScrollY - lastScrollY > SCROLL_DELTA) {
                setHideHeader(true);
            } else if (lastScrollY - currentScrollY > 0) {
                setHideHeader(false);
            }
            lastScrollY = currentScrollY;

            // NEW: Toggle the navbar background
            if (window.scrollY > 0) {
                setHasScrolled(true);
            } else {
                setHasScrolled(false);
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        // Track last scroll position
        let lastScrollY = window.pageYOffset;

        const handleScroll = () => {
            const currentScrollY = window.pageYOffset;
            // If scrolled down by more than SCROLL_DELTA, hide header
            if (currentScrollY - lastScrollY > SCROLL_DELTA) {
                setHideHeader(true);
            }
            // If scrolled up, show header
            else if (lastScrollY - currentScrollY > 0) {
                setHideHeader(false);
            }
            lastScrollY = currentScrollY;
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);
    useEffect(() => {
        if (postId) {
            const foundPost = posts.find((p) => p.id === parseInt(postId, 10)); // Convert postId to number
            setPost(foundPost || null); // Set the post or null if not found
        }
    }, [postId]);

    if (!post) {
        return <div>Loading or post not found...</div>; // Handle loading or post not found
    }

    const MarkdownViewer = ({ markdownContent }: { markdownContent: string }) => {
        return <ReactMarkdown
            children={markdownContent}
            remarkPlugins={[]}
            rehypePlugins={[]}
        />
    };

    return (
        <div className="bg-gray-50 min-h-screen flex flex-col">
            {/**
       * HEADER
       * Matches your "bento-style" from the landing page: 
       * - a border & rounded corners 
       * - set against a gray-50 background 
       */}
            <header
                className={`
                        sticky top-0 z-50 transition-all duration-300
                        ${hasScrolled ? 'bg-white/50 backdrop-blur-sm' : 'bg-gray-50'}
                        
                      `}
            >
                <nav aria-label="Global" className="mx-auto
      flex
      max-w-7xl
      items-center
      justify-between
      px-4
      py-3  
      lg:px-8">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                alt=""
                                src={logoLato}
                                className="h-8 w-auto"
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="size-6" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm/6 font-semibold text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                        {user ? (
                            <a
                                href="/activity"
                                className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
                            >
                                Dashboard
                            </a>
                        ) : (
                            <>
                                <a
                                    href="/auth?type=login"
                                    className="rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                >
                                    Log In
                                </a>
                                <a
                                    href="/auth?type=register"
                                    className="ml-4 rounded-md border border-indigo-600 px-3 py-1.5 text-md font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Register
                                </a>
                            </>
                        )}
                    </div>
                </nav>
                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-10" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Your Company</span>
                                <img
                                    alt=""
                                    src={logoLato}
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="size-6" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    {user ? (
                                        <a
                                            href="/activity"
                                            className="rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                        >
                                            Dashboard
                                        </a>
                                    ) : (
                                        <>
                                            <a
                                                href="/auth?type=login"
                                                className="block w-full rounded-md bg-indigo-600 px-4 py-2 text-base font-semibold text-white hover:bg-indigo-500 transition duration-200"
                                            >
                                                Log In
                                            </a>
                                            <a
                                                href="/auth?type=register"
                                                className="mt-3 block w-full rounded-md border border-indigo-600 px-4 py-2 text-base font-semibold text-indigo-600 hover:bg-indigo-600 hover:text-white transition duration-200"
                                            >
                                                Register
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>

            </header>
            {/**
       * MAIN CONTENT
       * Here we simply display your HTML with dangerouslySetInnerHTML,
       * but without the Tailwind Typography plugin. 
       */}
            <main className="flex-grow py-14 sm:py-20">
                <article className="mx-auto max-w-5xl px-6 lg:px-8">
                    {/* Post Header */}
                    <div className="text-center">
                        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
                            {post.title}
                        </h1>
                        <time
                            dateTime={post.datetime}
                            className="block text-sm font-medium text-gray-500 mt-2"
                        >
                            {post.date}
                        </time>
                    </div>

                    {/* Optional Divider */}
                    <hr className="mt-8 border-gray-300" />

                    {/* Post Content (raw HTML) */}
                    <div className="mt-8 prose prose-xl max-w-none text-gray-800">
                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    </div>
                </article>
            </main>

            {/**
       * FOOTER
       * Consistent with landing page style: 
       * white background + subtle top border 
       */}
            <footer className="bg-white border-t border-gray-300 mt-auto text-gray-600">
                <div className="mx-auto max-w-7xl py-6 px-6 lg:px-8 flex flex-col sm:flex-row items-center justify-between text-sm">
                    <p className="text-center sm:text-left">
                        © 2024 HinterviewGPT. All rights reserved.
                    </p>
                    <div className="mt-4 sm:mt-0">
                        <a
                            href="/privacy"
                            className="hover:text-indigo-500 transition ml-4"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href="/tos"
                            className="hover:text-indigo-500 transition ml-4"
                        >
                            Terms of Service
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default BlogPost;
