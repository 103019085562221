import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Auth } from './Auth';
import GenerateQuestion from './GenerateQuestion';
import AddManual from './AddManual';
import Account from './account';
import AddQuestion from './AddQuestion';
import Question from './Question';
import QuestionList from './QuestionList';
import Activity from './Activity';
import { ForgotPassword } from './ForgotPassword';
import LandingPage from './LandingPage';
import Help from './Help';
import Error from './Error';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TOS';
import Blog from './blog';
import Success from './success';
import NoAccess from './noAccess';
import Plan from './plan';
import BlogPost from './BlogPost';
import {VerifyEmail} from './VerifyEmail'

const MainRouter = () => {
  return (
    <Router>
        <Routes>
          <Route path="/questions" element={<QuestionList />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/add-manual" element={<AddManual />} />
          <Route path="/generate-question" element={<GenerateQuestion />} />
          <Route path="/question/:questionId" element={<Question />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path="/account" element={<Account />} />
          <Route path="/" element={<LandingPage />} />
          <Route path='/add' element={<AddQuestion/>} />
          <Route path='/activity' element={<Activity/>} /> 
          <Route path='/help' element={<Help/>} /> 
          <Route path='/privacy' element={<PrivacyPolicy/>} /> 
          <Route path='/tos' element={<TermsOfService/>} /> 
          <Route path='/blog' element={<Blog/>} /> 
          <Route path="/blog/:postId" element={<BlogPost />} />
          <Route path='/success' element={<Success/>} />
          <Route path='/no-access' element={<NoAccess/>} />
          <Route path="*" element={<Error />} /> 
          <Route path='/upgrade' element={<Plan/>} />
          <Route path='/verify-email' element={<VerifyEmail/>} />

        </Routes>
    </Router>
  );
};

export default MainRouter;

