import { Allotment } from "allotment";
import "allotment/dist/style.css";
import ChatUI from './ChatUI';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import OpenAI from 'openai';
import QuestionDisplay from "./QuestionDisplay";
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from './config/firebase';
import { doc, getDoc, updateDoc, setDoc, collection, query, where, getDocs, increment } from 'firebase/firestore';
import { auth } from './config/firebase';
import { User, signOut } from "firebase/auth";
import QuestionEditor from "./QuestionEditor";
import ReactMarkdown from 'react-markdown';
import { Disclosure, Menu, Transition, Dialog, MenuButton, MenuItem, MenuItems, TransitionChild, DialogPanel, DialogTitle } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  MoonIcon,
  ChevronDownIcon,
  CheckIcon,
  QueueListIcon,
} from '@heroicons/react/20/solid'
import { Typography, IconButton, Tooltip, Button } from '@mui/material';
import logo from './assets/_7afa50e7-4fe5-42b5-a782-8293c4c5172e-removebg-preview.png'
import Timer from "./Timer";
import { Dialog as MUIDialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconEdit, IconLogout, IconSettings, IconX } from '@tabler/icons-react';
import Editor from "@monaco-editor/react";
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { PencilIcon, EyeIcon, EyeSlashIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import saveActivity from "./utils/saveActivity";
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { IconLayout, IconArrowsShuffle, IconListNumbers, IconCopy } from "@tabler/icons-react";
import 'prismjs/themes/prism-tomorrow.css'; // Or any other Prism theme
import rehypePrism from 'rehype-prism-plus'
import { tutorPrompt } from "./Prompts";
import CloseIcon from '@mui/icons-material/Close';
import { planModelLimits, modelMapping } from './modelLimits';
import Pre from './copy';
import { IconHelp } from '@tabler/icons-react';


const Question = () => {
  const { questionId } = useParams();
  const [textAnswer, setTextAnswer] = useState('');
  const [codeAnswer, setCodeAnswer] = useState('');
  const [lastSavedText, setLastSavedText] = useState('');
  const [lastSavedCode, setLastSavedCode] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('python');
  const [leetCodeQuestion, setLeetCodeQuestion] = useState('');
  const [leetCodeTitle, setLeetCodeTitle] = useState('');
  const [leetCodeAnswer, setLeetCodeAnswer] = useState('');
  const [answerType, setAnswerType] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [createMethod, setCreateMethod] = useState('');
  const [createModel, setCreateModel] = useState('');
  const [user, setUser] = useState<User | null>(null);
  const [savedCode, setSavedCode] = useState('');
  const [aiEvalResult, setAiEvalResult] = useState<string | null>('');
  const [openModal, setOpenModal] = useState(false);
  const [fetchedCode, setFetchedCode] = useState<Array<{ code: string; aiEval: string, timestamp: string, language: string, model: string }> | null>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modelName, setModelName] = useState<string>('');
  const [isEditorMaximized, setIsEditorMaximized] = useState(false);
  const [isChatMaximized, setIsChatMaximized] = useState(false);
  const [isDisplayMaximized, setIsDisplayMaximized] = useState(false);
  const [open, setOpen] = useState(true)
  const [questionListOpen, setQuestionListOpen] = useState(false)
  const cancelButtonRef = useRef(null);
  const [apiKey, setApiKey] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newApiKey, setNewApiKey] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isAnonymized, setIsAnonymized] = useState(true);
  const [originalData, setOriginalData] = useState<QuestionType[]>([]);
  const [prevQuestion, setPrevQuestion] = useState<string | null>('');
  const [nextQuestion, setNextQuestion] = useState<string | null>('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number | null>(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const [maxSubmissionDialogOpen, setMaxSubmissionDialogOpen] = useState(false);
  const [manuallyGenAnswer, setManuallyGenAnswer] = useState<string | null>('');
  const [genAnswerLoading, setGenAnswerLoading] = useState(false);
  const [isEditorFolded, setIsEditorFolded] = useState(false);
  const [isChatFolded, setIsChatFolded] = useState(false);
  const [copied, setCopied] = useState(false);

  // ---- New single error dialog states ----
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Keep usage-limit and max-submission dialogs
  const [limitDialogOpen, setLimitDialogOpen] = useState(false);
  const [exceededModel, setExceededModel] = useState<string | null>(null);
  const [currentUsage, setCurrentUsage] = useState<number>(0);
  const [modelLimit, setModelLimit] = useState<number>(0);

  // Used to handle plan-based logic
  const [isActivated, setIsActivated] = useState(true);
  const [cancelAtNextPeriod, setCancelAtNextPeriod] = useState<boolean>(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('Inactive');
  const [trialRemaining, setTrialRemaining] = useState<number | null>(null);
  const [plan, setPlan] = useState('');
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [usageStats, setUsageStats] = useState<{ [key: string]: number }>({});
  const [messages, setMessages] = useState([
    { role: 'system', content: tutorPrompt },
    { role: 'assistant', content: "Hi there! If you need any assistance with this problem, I'd be happy to help!" }
  ]);

  const navigate = useNavigate();
  const ref: any = useRef(null);
  const innerRef: any = useRef(null);

  type QuestionType = {
    qRef: string;
    title: string;
    tags: string[];
    difficulty: string;
    answerType: string;
  };

  /***********************************
   ********** SUBSCRIPTIONS **********
   ***********************************/
  useEffect(() => {
    const fetchSubscriptions = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const subscriptionsRef = collection(db, 'customers', currentUser.uid, 'subscriptions');
      const activeSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

      const subscriptionSnap = await getDocs(activeSubscriptionsQuery);
      const subs: any[] = [];

      subscriptionSnap.forEach((doc) => {
        subs.push(doc.data());
      });

      if (subs.length > 0) {
        const activeSubscription = subs[0];
        if (activeSubscription.cancel_at_period_end) {
          setCancelAtNextPeriod(true);
        }
        setSubscriptionStatus(activeSubscription.status);
        const planNickname = activeSubscription.items[0]?.price?.product?.name || 'None';
        setPlan(planNickname);
        setHasAccess(true);
        return;
      } else {
        setPlan('None');
      }

      // 2. Check for free trial eligibility
      const signUpDate = new Date(currentUser.metadata.creationTime!);
      const currentDate = new Date();
      const timeDiff = currentDate.getTime() - signUpDate.getTime();
      const daysSinceSignUp = Math.floor(timeDiff / (1000 * 3600 * 24));

      if (daysSinceSignUp < 7) {
        setTrialRemaining(7 - daysSinceSignUp);
        setHasAccess(true);
        return;
      }

      setHasAccess(false);
      setSubscriptionStatus('Inactive');
    };

    fetchSubscriptions();
  }, [user]);

  const fetchUsageStats = async (authUser: User) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const userId = authUser.uid;

    const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

    try {
      const userUsageSnap = await getDoc(userUsageRef);
      if (userUsageSnap.exists()) {
        const data = userUsageSnap.data();
        setUsageStats(data.models || {});
      } else {
        setUsageStats({});
      }
    } catch (error) {
      console.error('Error fetching usage stats:', error);
    }
  };

  /***********************************
   ************* MESSAGES  ***********
   ***********************************/
  useEffect(() => {
    // Update the initial system prompt with the new leetCodeAnswer
    const updatedPrompt =
      `${tutorPrompt} \nHere is a reference solution to this question that you can use to determine if the user's solution is correct.` +
      leetCodeAnswer +
      `. Pay close attention to the differences between the user's solution and the reference solution.  Never let the user know that you are aware of the reference solution.`.trim().replace(/(\r\n|\n|\r)/gm, '');

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[0] = { role: 'system', content: updatedPrompt };
      return updatedMessages;
    });
  }, [leetCodeAnswer]);

  /***********************************
   ************ AUTH STUFF ***********
   ***********************************/
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        fetchUsageStats(authUser);
      } else {
        setUser(null);
        navigate('/auth');
      }
    });
    return () => unsubscribe();
  }, []);

  /***********************************
   ************ LOCAL STORAGE ********
   ***********************************/
  useEffect(() => {
    const userId = user?.uid || '';
    const storedApiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`) || '';
    const storedModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`) || '';
    setModelName(storedModelName);
    setApiKey(storedApiKey);
    setNewApiKey(storedApiKey);
    setIsActivated(storedApiKey !== '' && localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true');
  }, [user]);

  useEffect(() => {
    if (leetCodeTitle) {
      document.title = leetCodeTitle + ' | HinterviewGPT';
    } else {
      document.title = 'Question';
    }
  }, [leetCodeTitle]);

  /***********************************
   ********** FETCH QUESTION *********
   ***********************************/
  useEffect(() => {
    const fetchQuestion = async () => {
      if (questionId && user) {
        try {
          const questionDocRef = doc(db, 'userQuestions', questionId);
          const docSnap = await getDoc(questionDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (data.user !== user.uid) {
              navigate('/error');
              return;
            }
            setLeetCodeQuestion(data.body);
            setLeetCodeAnswer(data.answer || '');
            setLeetCodeTitle(data.title);
            setAnswerType(data.answerType);
            setTags(data.tags || []);
            setCreateMethod(data.method || '');
            setCreatedDate(data.createdAt || '');
            setCreateModel(data.model || '');
          } else {
            navigate('/error');
          }
        } catch (error) {
          console.error('Error fetching question:', error);
          navigate('/error');
        }
      }
    };

    if (questionId && user) {
      fetchQuestion();
    }
  }, [questionId, user]);

  /***********************************
   ********** UI LAYOUT UTILS ********
   ***********************************/
  const minimizeEditorPane = () => {
    if (innerRef.current) {
      innerRef.current.resize([2, 1000]);
      setIsEditorFolded(true);
    }
  };
  const unminimizeEditorPane = () => {
    if (innerRef.current) {
      innerRef.current.reset();
      setIsEditorFolded(false);
    }
  };

  const minimizeChatPane = () => {
    if (innerRef.current) {
      innerRef.current.resize([1000, 2]);
      setIsChatFolded(true);
    }
  };
  const unminimizeChatPane = () => {
    if (innerRef.current) {
      innerRef.current.reset();
      setIsChatFolded(false);
    }
  };

  const shouldMaximizeDisplay =
    isDisplayMaximized || (!isChatMaximized && !isEditorMaximized);

  /***********************************
   ********** AUTO-SAVE LOGIC ********
   ***********************************/
  useEffect(() => {
    const saveInterval = setInterval(() => {
      if (answerType === 'Text' && textAnswer !== lastSavedText) {
        localStorage.setItem(`savedText_${questionId}`, textAnswer);
        setLastSavedText(textAnswer);
      } else if (answerType === 'Code' && codeAnswer !== lastSavedCode) {
        localStorage.setItem(`savedCode_${questionId}`, codeAnswer);
        setLastSavedCode(codeAnswer);
      }
    }, 3000);

    return () => clearInterval(saveInterval);
  }, [textAnswer, codeAnswer, answerType, questionId, lastSavedText, lastSavedCode]);

  useEffect(() => {
    const savedText = localStorage.getItem(`savedText_${questionId}`);
    const savedCode = localStorage.getItem(`savedCode_${questionId}`);

    if (answerType === 'Text') {
      setTextAnswer(savedText || '');
    } else if (answerType === 'Code') {
      setCodeAnswer(savedCode || '');
    }
  }, [questionId, answerType]);

  /***********************************
   ******* PREVIEW / COPY UTILS ******
   ***********************************/
  const handleExportToWhiteboard = (code: string) => {
    if (answerType === 'Code') {
      setCodeAnswer(code);
    } else {
      setTextAnswer(code);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCopy = () => {
    const contentToCopy = answerType === 'Code' ? codeAnswer : textAnswer;
    navigator.clipboard.writeText(contentToCopy || '');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  /***********************************
   *********** MODEL DROPDOWN ********
   ***********************************/

  const filteredMethods = plan === 'None'
    ? modelMapping.filter(method => method.id === 'gpt-4o-mini-2024-07-18')
    : modelMapping;

  const getModelTitle = (id: string) => {
    const method = modelMapping.find((m) => m.id === id);
    return method ? method.title : 'Unknown';
  };

  const handleModelChange = (id: string) => {
    setModelName(id);
    const userId = user?.uid || '';
    localStorage.setItem(`hinterviewGpt_modelName_${userId}`, id);
  };

  /***********************************
   *********** QUESTION LIST *********
   ***********************************/
  type QType = {
    qRef: string;
    title: string;
    tags: string[];
    difficulty: string;
    answerType: string;
  };

  const getLeetCodeQuestions = async () => {
    if (!user) return;
    try {
      const userQuestionsListRef = doc(db, 'userQuestionList', user.uid);
      const userQuestionsListDoc = await getDoc(userQuestionsListRef);

      if (userQuestionsListDoc.exists()) {
        const userQuestionsList = userQuestionsListDoc.data().questions || [];
        const queriedData: QType[] = userQuestionsList.map((q: QType) => {
          const { title, difficulty, qRef, tags, answerType } = q;
          return { qRef, title, tags, difficulty, answerType };
        });
        setOriginalData(queriedData);
      }
    } catch (err) {
      console.error('Error getting documents: ', err);
    }
  };

  useEffect(() => {
    if (user) {
      getLeetCodeQuestions();
    }
  }, [user, leetCodeQuestion]);

  useEffect(() => {
    const getPrevNextQuestion = async () => {
      const currentIndex = originalData.findIndex((question) => question.qRef === questionId);
      if (currentIndex === -1) return;

      const prevQ = currentIndex > 0 ? originalData[currentIndex - 1].qRef : null;
      const nextQ = currentIndex < originalData.length - 1 ? originalData[currentIndex + 1].qRef : null;

      setPrevQuestion(prevQ);
      setNextQuestion(nextQ);
      setCurrentQuestionIndex(currentIndex);
    };
    if (originalData && questionId) {
      getPrevNextQuestion();
    }
  }, [originalData, questionId]);

  useEffect(() => {
    handleClearConversation();
  }, [questionId]);

  const handleClearConversation = () => {
    setMessages([
      messages[0],
      messages[1],
    ]);
  };

  /***********************************
   ************ THEME TOGGLE *********
   ***********************************/
  const handleToggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  /***********************************
   ************* NAV STUFF ***********
   ***********************************/
  function classNames(...classes: (string | false | null | undefined)[]) {
    return classes.filter(Boolean).join(' ')
  }

  const navigation = [
    { name: 'Question List', href: '', current: false },
  ];

  const logOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      navigate('/auth');
    } catch (err: any) {
      console.log(err);
    }
  };

  const openDialog = async () => {
    if (user) {
      await fetchUsageStats(user);
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setIsAnonymized(true);
  };

  const anonymizeApiKey = (key: string) => {
    return key ? '*'.repeat(key.length) : '';
  };

  const handleActivateApiKey = () => {
    const userId = user?.uid || '';
    localStorage.setItem(`hinterviewGpt_openAi_apiKey_${userId}`, newApiKey);
    localStorage.setItem(`hinterviewGPt_apiKey_isActivated_${userId}`, 'true');
    setApiKey(newApiKey);
    setIsActivated(true);
    setIsAnonymized(true);
  };

  const handleDeactivateApiKey = () => {
    const userId = user?.uid || '';
    localStorage.removeItem(`hinterviewGpt_openAi_apiKey_${userId}`);
    localStorage.setItem(`hinterviewGPt_apiKey_isActivated_${userId}`, 'false');
    setApiKey('');
    setIsActivated(false);
    setIsAnonymized(true);
  };

  /***********************************
   ***** SUBMISSIONS / SAVE CODE *****
   ***********************************/
  const updateFetchedCode = async () => {
    if (user && questionId) {
      try {
        const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${questionId}`);
        const docSnap = await getDoc(userAttemptDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFetchedCode(data.code || []);
        }
      } catch (error) {
        console.error('Error fetching saved code:', error);
      }
    }
  };

  useEffect(() => {
    if (user && questionId) {
      (async () => {
        try {
          const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${questionId}`);
          const docSnap = await getDoc(userAttemptDocRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setFetchedCode(data.code || []);
          }
        } catch (error) {
          console.error('Error fetching saved code:', error);
        }
      })();
    }
  }, [user, questionId]);

  const handleDeleteCode = async (submissionIndex: number) => {
    if (user && questionId) {
      try {
        const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${questionId}`);
        const docSnap = await getDoc(userAttemptDocRef);
        if (docSnap.exists()) {
          const currentCode = docSnap.data().code || [];
          const updatedCode = currentCode.filter((_: string, i: number) => i !== submissionIndex);

          await updateDoc(userAttemptDocRef, { code: updatedCode });

          const userSubmissionNumDocRef = doc(db, 'userSubmissionNum', user.uid);
          const userSubmissionNumDoc = await getDoc(userSubmissionNumDocRef);

          if (userSubmissionNumDoc.exists()) {
            const currentData = userSubmissionNumDoc.data();
            const currentQuestionSubmissions = currentData.questionSubmissions || {};
            const updatedQuestionSubmissions = {
              ...currentQuestionSubmissions,
              [questionId]: updatedCode.length || 0,
            };
            await updateDoc(userSubmissionNumDocRef, { questionSubmissions: updatedQuestionSubmissions });
          }

          updateFetchedCode();
        }
      } catch (error) {
        console.error('Error deleting code:', error);
      }
    }
  };

  // ============ handleSaveCode ============
  const handleSaveCode = async () => {
    const userId = user?.uid || '';
    const localApiKey = localStorage.getItem(`hinterviewGpt_openAi_apiKey_${userId}`);
    const isApiKeyActivated = localStorage.getItem(`hinterviewGPt_apiKey_isActivated_${userId}`) === 'true';
    const latestModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`) || '';

    // 1) Check plan & model
    const planLimits = planModelLimits[plan];
    const limitForModel = planLimits?.[latestModelName] || 0;
    if (!planLimits || !limitForModel) {
      console.error('Selected plan or model limit not recognized.');
      setErrorDialogTitle('Model/Plan Error');
      setErrorMessage('We could not find matching usage limits for your current plan/model. Please select a valid plan or model.');
      setErrorDialogOpen(true);
      return;
    }

    // 2) Decide which API key
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY


    // 4) Check subscription/trial
    if (!hasAccess) {
      console.error('7-day free trial expired or no active subscription');
      setErrorDialogTitle('Subscription Required');
      setErrorMessage('Your 7-day free trial has expired or you have no active subscription. Please upgrade to continue.');
      setErrorDialogOpen(true);
      return;
    }

    // 5) Check if no key at all
    if (!apiKey) {
      console.error('API key is not available.');
      setErrorDialogTitle('Missing API Key');
      setErrorMessage('No API key found. Please set or activate your OpenAI key to proceed.');
      setErrorDialogOpen(true);
      return;
    }

    // 6) Must have a question ID
    if (!questionId) {
      console.error('No question ID available.');
      setErrorDialogTitle('Question Error');
      setErrorMessage('Cannot save code because questionId is missing.');
      setErrorDialogOpen(true);
      return;
    }

    // If all checks pass...
    if (user && questionId && latestModelName) {
      const code = (answerType === 'Code') ? codeAnswer : textAnswer;

      // 7) Check usage
      await fetchUsageStats(user);
      const userUsage = usageStats[latestModelName] || 0;
      if (userUsage >= limitForModel) {
        console.error('API request limit reached for the current month.');
        setExceededModel(latestModelName);
        setCurrentUsage(userUsage);
        setModelLimit(limitForModel);
        setLimitDialogOpen(true);
        return;
      }

      // 8) Check max submissions
      if (fetchedCode && fetchedCode.length > 9) {
        setMaxSubmissionDialogOpen(true);
        return;
      }

      // 9) Evaluate and save
      try {
        setOpenModal(true);
        setAiEvalResult(null);

        // Construct the system content in parts.
        let systemContent = `
        You are an expert interview question evaluator who evaluates users solutions to interview questions.
        The user has now submitted their solution which you will see in the next message starting with 'User's Solution:'. 
Determine if it is correct or not. 
Do not add anything to the user's solution to make it correct.`;

        // Only add reference solution part if leetCodeAnswer is non-empty:
        if (leetCodeAnswer) {
          systemContent += `

This is the reference solution: ${leetCodeAnswer}

Check for differences between the reference solution and the user solution. 
Just use the reference solution as a reference, do not mention it in your evaluation.
`;
        }

        // Add final instructions
        systemContent += `

If the user's solution is correct, mention the important aspects of the solution that the user got right. 
If the user's solution is not correct, clearly identify what is incorrect about it and provide the corrected solution.  You are speaking
directly to the user so make sure to be upbeat and encouraging.
`

        const systemOrUserRole =
          latestModelName === 'o1-mini-2024-09-12' ? 'user' : 'system';
        // Now construct the `initialMessages`.
        const initialMessages: any[] = [
          { role: systemOrUserRole, content: `Question: ${leetCodeQuestion}` },
          {
            role: systemOrUserRole,
            content: systemContent,
          },
          { role: systemOrUserRole, content: `User's Solution: ${code}` },
        ];
        const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

        // fetch current usage doc
        let requestCount = 0;
        try {
          const userUsageSnap = await getDoc(userUsageRef);
          if (userUsageSnap.exists()) {
            const data = userUsageSnap.data();
            requestCount = data.models[latestModelName] || 0;
          }
        } catch (error) {
          console.error('Error fetching user usage data:', error);
        }

        // Stream response
        let aiEval = '';
        const chatCompletion = await openai.chat.completions.create({
          messages: initialMessages,
          model: latestModelName,
          stream: true
        });

        for await (const part of chatCompletion) {
          aiEval += part.choices[0]?.delta?.content || '';
          setAiEvalResult(aiEval);
        }

        // Update usage doc
        try {
          await updateDoc(userUsageRef, { [`models.${latestModelName}`]: increment(1) });
        } catch (error: any) {
          if (error.code === 'not-found') {
            await setDoc(userUsageRef, { year, month, models: { [latestModelName]: 1 } });
          } else {
            console.error("Error updating user usage:", error);
          }
        }

        // Save attempt doc
        const userAttemptDocRef = doc(db, 'userSubmissions', `${user.uid}_${questionId}`);
        const docSnap = await getDoc(userAttemptDocRef);

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options: Intl.DateTimeFormatOptions = {
          timeZone: userTimeZone, year: 'numeric', month: '2-digit',
          day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'
        };
        const formattedDate = currentDate.toLocaleDateString(undefined, options);

        let lang = selectedLanguage;
        if (answerType === 'Text') {
          lang = 'text';
        }

        const newSubmission = { code, aiEval, timestamp: formattedDate, language: lang, model: getModelTitle(modelName) };
        const userAttemptData = {
          userID: user.uid,
          questionID: questionId,
          code: docSnap.exists()
            ? [...docSnap.data().code, newSubmission]
            : [newSubmission],
        };

        try {
          if (docSnap.exists()) {
            await updateDoc(userAttemptDocRef, userAttemptData);
          } else {
            await setDoc(userAttemptDocRef, userAttemptData);
          }

          const userSubmissionNumDocRef = doc(db, 'userSubmissionNum', user.uid);
          const userSubmissionNumDoc = await getDoc(userSubmissionNumDocRef);
          if (userSubmissionNumDoc.exists()) {
            const currentData = userSubmissionNumDoc.data();
            const currentSubCount = userAttemptData.code.length || 0;
            const updatedQuestionSubmissions = {
              ...currentData.questionSubmissions,
              [questionId]: currentSubCount
            };
            await updateDoc(userSubmissionNumDocRef, { questionSubmissions: updatedQuestionSubmissions });
          } else {
            await setDoc(userSubmissionNumDocRef, { questionSubmissions: { [questionId]: 1 } });
          }

          if (answerType === 'Code') {
            setSavedCode(codeAnswer);
          } else {
            setSavedCode(textAnswer);
          }

          updateFetchedCode();
          await saveActivity(user.uid, "submitCount");
        } catch (error) {
          console.error('Error saving code:', error);
        }

      } catch (error) {
        console.error('Error in handleSaveCode:', error);
        setErrorDialogTitle('Submission Error');
        setErrorMessage('Something went wrong while saving your code. Please try again later.');
        setErrorDialogOpen(true);
      }
    }
  };

  // ============ genSolution ============
  const genSolution = async () => {
    const userId = user?.uid || '';
    const latestModelName = localStorage.getItem(`hinterviewGpt_modelName_${userId}`);

    const apiKey = process.env.REACT_APP_OPENAI_API_KEY


    // 3) Must have questionId
    if (!questionId) {
      console.error('No question ID available.');
      setErrorDialogTitle('Question Error');
      setErrorMessage('Cannot generate a solution because the questionId is missing.');
      setErrorDialogOpen(true);
      return;
    }

    // 4) Must have recognized model
    if (!latestModelName) {
      console.error('Selected model name not recognized.');
      setErrorDialogTitle('Model Error');
      setErrorMessage('The currently selected model is not recognized. Please pick a valid ChatGPT model.');
      setErrorDialogOpen(true);
      return;
    }

    // 5) Check subscription/trial
    if (!hasAccess) {
      console.error('7-day free trial expired or no active subscription');
      setErrorDialogTitle('Subscription Required');
      setErrorMessage('Your 7-day free trial has expired or you have no active subscription. Please upgrade to continue.');
      setErrorDialogOpen(true);
      return;
    }

    if (!apiKey) {
      console.error('API key is not available.');
      setErrorDialogTitle('Missing API Key');
      setErrorMessage('No API key found. Please set or activate your key before generating a solution.');
      setErrorDialogOpen(true);
      return;
    }

    // If all checks pass
    if (user) {
      try {
        setGenAnswerLoading(true);

        const systemOrUserRole =
                latestModelName === 'o1-mini-2024-09-12' ? 'user' : 'system';

        const initialMessages: any[] = [
          {
            role: systemOrUserRole,
            content: `You are an expert in solving job interview questions. Generate the correct answer for this question. 
                      If multiple solutions exist, generate up to 2.`
          },
          { role: systemOrUserRole, content: `Question: ${leetCodeQuestion}` },
        ];

        const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

        // Check usage
        await fetchUsageStats(user);
        const userUsage = usageStats[latestModelName] || 0;
        const planLimits = planModelLimits[plan];
        const limitForModel = planLimits?.[latestModelName] || 0;

        if (!limitForModel) {
          console.error('Model limit not found for the current plan.');
          setErrorDialogTitle('Model Limit Error');
          setErrorMessage('No recognized usage limit for your plan/model. Please select a valid plan or model.');
          setErrorDialogOpen(true);
          setGenAnswerLoading(false);
          return;
        }

        if (userUsage >= limitForModel) {
          console.error('API request limit reached for the current month.');
          setExceededModel(latestModelName);
          setCurrentUsage(userUsage);
          setModelLimit(limitForModel);
          setLimitDialogOpen(true);
          setGenAnswerLoading(false);
          return;
        }

        // Actually call the model
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const userUsageRef = doc(db, 'userUsage', `${userId}_${year}_${month}`);

        let requestCount = 0;
        try {
          const userUsageSnap = await getDoc(userUsageRef);
          if (userUsageSnap.exists()) {
            const data = userUsageSnap.data();
            requestCount = data.models[latestModelName] || 0;
          }
        } catch (error) {
          console.error('Error fetching user usage data:', error);
        }

        const chatCompletion = await openai.chat.completions.create({
          messages: initialMessages,
          model: latestModelName,
          stream: false
        });

        const generatedAnswer = chatCompletion.choices[0]?.message?.content || '{}';
        setGenAnswerLoading(false);
        setLeetCodeAnswer(generatedAnswer);

        // Update usage doc
        try {
          await updateDoc(userUsageRef, {
            [`models.${latestModelName}`]: increment(1)
          });
        } catch (error: any) {
          if (error.code === 'not-found') {
            await setDoc(userUsageRef, {
              year,
              month,
              models: { [latestModelName]: 1 }
            });
          } else {
            console.error("Error updating user usage:", error);
          }
        }

        // Save generated answer to Firestore
        const questionDocRef = doc(db, 'userQuestions', questionId);
        await updateDoc(questionDocRef, { answer: generatedAnswer });
      } catch (error) {
        console.error('Error generating solution:', error);
        setGenAnswerLoading(false);
        setErrorDialogTitle('Solution Generation Error');
        setErrorMessage('Something went wrong while generating a solution. Please try again later.');
        setErrorDialogOpen(true);
      }
    }
  };

  /***********************************
   ********** RENDER METHODS *********
   ***********************************/
  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };
  const handleCodeRefresh = () => {
    setCodeAnswer('');
    setTextAnswer('');
  };

  const RenderHTMLContent: React.FC<{ content: string }> = ({ content }) => {
    return <div className="custom-html-content-2" dangerouslySetInnerHTML={{ __html: content }} />;
  };

  const preprocessLaTeX = (content: string) => {
    const blockProcessedContent = content.replace(
      /\\\[(.*?)\\\]/gs,
      (_, equation) => `$$${equation}$$`,
    );
    const inlineProcessedContent = blockProcessedContent.replace(
      /\\\((.*?)\\\)/gs,
      (_, equation) => `$${equation}$`,
    );
    return inlineProcessedContent;
  };

  const MarkdownViewer = ({ markdownContent }: { markdownContent: string }) => {
    const rehypePrismOptions = {
      showLineNumbers: true,
      ignoreMissing: true,
      defaultLanguage: 'c',
    };
    return (
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[[rehypeKatex], [rehypePrism, rehypePrismOptions]]}
        components={{ pre: (props) => <Pre {...props} /> }}
      >
        {preprocessLaTeX(markdownContent)}
      </ReactMarkdown>
    );
  };

  const handleShuffle = () => {
    if (originalData.length > 0) {
      const randomIndex = Math.floor(Math.random() * originalData.length);
      const randomQuestion = originalData[randomIndex];
      navigate(`/question/${randomQuestion.qRef}`);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="h-screen " style={{ backgroundColor: '#ebebeb' }}>
        {/** =============== NAVBAR =============== **/}
        <Disclosure as="nav" style={{ backgroundColor: '#ebebeb' }}>
          {({ open }) => (
            <>
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-2">
                <div className="flex h-11 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex items-center justify-center h-screen">
                      <img
                        className="h-9 w-auto cursor-pointer ml-2"
                        src={logo}
                        onClick={() => navigate(`/activity`)}
                        alt="Your Company"
                      />
                    </div>
                    {/** Vertical Line Divider **/}
                    <div className="mx-1 h-4 w-px bg-gray-300"></div>

                    <div className="hidden md:block">
                      <div className="flex items-center justify-center h-screen ">
                        <button
                          className="text-gray-800 px-2 py-2 rounded-lg hover:bg-stone-300 flex rounded-md px-3 py-2 text-sm font-medium items-center"
                          style={{ backgroundColor: '##ebebeb' }}
                          onClick={() => setQuestionListOpen(true)}
                        >
                          <IconListNumbers className="h-5 w-5 mr-1 text-gray-800" aria-hidden="true" />
                          <span className='text-gray-800'>Question List</span>
                        </button>

                        <Tooltip title="Prev Question" PopperProps={{
                          modifiers: [
                            { name: 'offset', options: { offset: [0, -6] } },
                          ],
                        }}>
                          <button
                            className={classNames(
                              'px-3 py-2 rounded-lg flex items-center',
                              prevQuestion ? 'text-gray-800 hover:bg-stone-300' : 'text-gray-400 cursor-not-allowed'
                            )}
                            style={{ backgroundColor: '##ebebeb' }}
                            onClick={() => navigate(`/question/${prevQuestion}`)}
                            disabled={!prevQuestion}
                          >
                            <ChevronLeftIcon className="h-5 w-5 text-gray-800" aria-hidden="true" />
                          </button>
                        </Tooltip>

                        <Tooltip title="Next Question" PopperProps={{
                          modifiers: [
                            { name: 'offset', options: { offset: [0, -6] } },
                          ],
                        }}>
                          <span>
                            <button
                              className={classNames(
                                'px-3 py-2 rounded-lg flex items-center',
                                nextQuestion ? 'text-gray-800 hover:bg-stone-300' : ' text-gray-400 cursor-not-allowed'
                              )}
                              style={{ backgroundColor: '##ebebeb' }}
                              onClick={() => navigate(`/question/${nextQuestion}`)}
                              disabled={!nextQuestion}
                            >
                              <ChevronRightIcon className="h-5 w-5 text-gray-800" aria-hidden="true" />
                            </button>
                          </span>
                        </Tooltip>

                        <Tooltip title="Shuffle" PopperProps={{
                          modifiers: [
                            { name: 'offset', options: { offset: [0, -6] } },
                          ],
                        }}>
                          <button
                            type="button"
                            className="text-gray-900 px-3 py-2 mr-0 ml-0 rounded-lg hover:bg-stone-300"
                            style={{ backgroundColor: '##ebebeb' }}
                            onClick={handleShuffle}
                          >
                            <IconArrowsShuffle className="h-5 w-5 text-gray-800" />
                          </button>
                        </Tooltip>

                      </div>
                    </div>
                  </div>

                  {/** Timer **/}
                  <div className="flex items-center justify-center h-screen mr-6">
                    <Timer />
                  </div>

                  {/** Right side (Model selection, Layout reset, Profile menu) **/}
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center justify-center h-screen">
                      <Tooltip title="Change Model" PopperProps={{
                        modifiers: [
                          { name: 'offset', options: { offset: [0, -6] } },
                        ],
                      }}>
                        <Menu as="div" className="relative">
                          <div>
                            <Menu.Button
                              className="relative flex max-w-xs items-center text-sm text-gray-900 px-2 py-2 mr-0 rounded-lg hover:bg-stone-300"
                              style={{ backgroundColor: '##ebebeb' }}
                              onClick={openDialog}
                            >
                              {modelMapping.find((m) => m.id === modelName)?.title}

                              <svg className="ml-1 h-5 w-5 opacity-70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                              </svg>
                            </Menu.Button>
                          </div>
                        </Menu>
                      </Tooltip>

                      <Tooltip title="Reset Layout" PopperProps={{
                        modifiers: [
                          { name: 'offset', options: { offset: [0, -6] } },
                        ],
                      }}>
                        <button
                          type="button"
                          className="text-gray-700 px-2 py-2 mr-0 ml-2 rounded-lg hover:bg-stone-300"
                          onClick={() => {
                            ref.current?.reset();
                            innerRef.current?.reset();
                          }}
                        >
                          <IconLayout className="h-5 w-5 rotate-180 text-gray-800" />
                        </button>
                      </Tooltip>

                      <div className="flex items-center ml-0 mr-2">
                        {/** Profile dropdown **/}
                        <Menu as="div" className="relative ml-2 flex-shrink-0">
                          <div>
                            <MenuButton className="relative flex rounded-full bg-white text-sm text-white focus:outline-none">
                              <span className="absolute -inset-1.5" />
                              <span className="sr-only">Open user menu</span>
                              {user?.photoURL ? (
                                <img className="h-6 w-6 rounded-full" src={user.photoURL} alt="" />
                              ) : (
                                <UserCircleIcon className="h-7 w-7 rounded-full border text-gray-500 border-gray-300" />
                              )}
                            </MenuButton>
                          </div>
                          <MenuItems
                            className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                          >
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  onClick={() => navigate('/help')}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 flex items-center'
                                  )}
                                >
                                  <IconHelp className="h-5 w-5 mr-1 text-gray-500" aria-hidden="true" />
                                  Help
                                </a>
                              )}
                            </Menu.Item>
                            <MenuItem>
                              {({ active }) => (
                                <a
                                  href=""
                                  onClick={() => navigate('/account')}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 flex items-center'
                                  )}
                                >
                                  <IconSettings className="h-5 w-5 mr-1 text-gray-500" aria-hidden="true" />
                                  Account Settings
                                </a>
                              )}
                            </MenuItem>
                            <MenuItem>
                              {({ active }) => (
                                <a
                                  href=""
                                  onClick={logOut}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 flex items-center'
                                  )}
                                >
                                  <IconLogout className="h-5 w-5 mr-1 text-gray-500 ml-0.5" aria-hidden="true" />
                                  Sign out
                                </a>
                              )}
                            </MenuItem>
                          </MenuItems>
                        </Menu>
                      </div>
                    </div>

                    {/** Model dialog below (with usage stats) **/}
                    <Transition.Root show={isDialogOpen} as={Fragment}>
                      <Dialog as="div" className="relative z-10" onClose={closeDialog}>
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              enterTo="opacity-100 translate-y-0 sm:scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                              <Dialog.Panel className="relative transform overflow-hidden h-92 rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 overflow-y-auto max-h-[90vh]">
                                <div className="absolute top-0 right-0 pt-4 pr-4">
                                  <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                    onClick={closeDialog}
                                  >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                  </button>
                                </div>
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                  Model Settings
                                </Dialog.Title>

                                {/** Model Selection Part **/}
                                <div className="mt-1 relative">
                                  <label className="block text-sm font-medium text-gray-700 mb-1 mt-6">Select Model</label>
                                  <Menu as="div" className="relative inline-block text-left w-full">
                                    <div className="cursor-pointer hover:text-gray-900 rounded flex items-center justify-center">
                                      <MenuButton className="inline-flex w-full justify-between items-center rounded-md px-3 py-2 bg-white text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none">
                                        {modelMapping.find((method) => method.id === modelName)?.title || 'Select Model'}
                                        <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-500" />
                                      </MenuButton>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <MenuItems className="absolute right-0 z-10 mt-1 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-auto">
                                        <div className="py-1">
                                          {filteredMethods.map((method) => (
                                            <MenuItem key={method.id}>
                                              {({ active }) => (
                                                <button
                                                  onClick={() => handleModelChange(method.id)}
                                                  className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                                    } block px-4 py-2 text-sm w-full text-left flex items-start justify-between`}
                                                >
                                                  <div>
                                                    <div>{method.title}</div>
                                                    <div className="text-xs text-gray-500">{method.subtitle}</div>
                                                  </div>
                                                  {method.id === modelName && (
                                                    <CheckIcon aria-hidden="true" className="h-5 w-5 text-indigo-600" />
                                                  )}
                                                </button>
                                              )}
                                            </MenuItem>
                                          ))}
                                        </div>
                                      </MenuItems>
                                    </Transition>
                                  </Menu>

                                </div>

                                {/** Conditionally render usage stats vs. personal API key block **/}
                                <div className="mt-8">
                                  <h4 className="text-md font-medium text-gray-900">Monthly Usage</h4>
                                  <ul className="mt-2 space-y-2">
                                    {Object.entries(planModelLimits[plan] || {}).map(([model, limit]) => {
                                      const usageCount = usageStats[model] ?? 0;
                                      const percentage = (usageCount / limit) * 100;
                                      const isNearLimit = percentage > 80;
                                      const isModerateUsage = percentage > 50 && percentage <= 80;

                                      // Find the model title from modelMapping
                                      const modelInfo = modelMapping.find(m => m.id === model);
                                      const displayTitle = modelInfo ? modelInfo.title : model;

                                      return (
                                        <div key={model} className="space-y-2">
                                          <div className="flex justify-between items-center">
                                            <span className="text-sm font-medium text-gray-700">{displayTitle}</span>
                                            <span className={`text-sm font-medium ${isNearLimit ? 'text-red-600' :
                                              isModerateUsage ? 'text-yellow-600' :
                                                'text-green-600'
                                              }`}>
                                              {usageCount} / {limit}
                                            </span>
                                          </div>
                                          <div className="w-full bg-gray-200 rounded-full h-2.5">
                                            <div
                                              className={`h-2.5 rounded-full ${isNearLimit ? 'bg-red-500' :
                                                isModerateUsage ? 'bg-yellow-500' :
                                                  'bg-green-500'
                                                }`}
                                              style={{ width: `${Math.min(percentage, 100)}%` }}
                                            ></div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </ul>
                                </div>

                              </Dialog.Panel>
                            </Transition.Child>
                          </div>
                        </div>
                      </Dialog>
                    </Transition.Root>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-300 text-gray-900'
                          : 'text-gray-900 hover:bg-gray-300 hover:bg-opacity-75',
                        'block rounded-md px-3 py-2 text-base font-medium cursor-pointer'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/** =============== END NAVBAR =============== **/}

        <div className='max-w-8xl mx-auto h-full' style={{ height: 'calc(100vh - 3rem)' }}>
          <Allotment separator={false} ref={ref}>
            <Allotment.Pane visible={shouldMaximizeDisplay} minSize={200} preferredSize={'48%'}>
              <QuestionDisplay
                leetCodeQuestion={leetCodeQuestion}
                leetCodeTitle={leetCodeTitle}
                answerType={answerType}
                leetCodeTags={tags}
                leetCodeAnswer={leetCodeAnswer}
                fetchedCode={fetchedCode}
                questionNumber={currentQuestionIndex}
                updateFetchedCode={updateFetchedCode}
                handleDeleteCode={handleDeleteCode}
                isDisplayMaximized={isDisplayMaximized}
                setIsDisplayMaximized={setIsDisplayMaximized}
                genSolution={genSolution}
                genAnswerLoading={genAnswerLoading}
                handleExportToWhiteboard={handleExportToWhiteboard}
                createMethod={createMethod}
                createDate={createdDate}
                createModel={createModel}
              />
            </Allotment.Pane>

            {!isDisplayMaximized && (
              <Allotment vertical separator={false} ref={innerRef} onChange={() => {
                setIsEditorFolded(false);
                setIsChatFolded(false);
              }}
              >
                <Allotment.Pane visible={!isChatMaximized} minSize={39}>
                  <QuestionEditor
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={handleLanguageChange}
                    handleCodeRefresh={handleCodeRefresh}
                    handleSaveCode={handleSaveCode}
                    codeAnswer={codeAnswer}
                    setCodeAnswer={setCodeAnswer}
                    textAnswer={textAnswer}
                    setTextAnswer={setTextAnswer}
                    answerType={answerType}
                    handleToggleTheme={handleToggleTheme}
                    isDarkMode={isDarkMode}
                    isEditorMaximized={isEditorMaximized}
                    isEditorFolded={isEditorFolded}
                    setIsEditorMaximized={setIsEditorMaximized}
                    minimizeEditorPane={minimizeEditorPane}
                    unminimizeEditorPane={unminimizeEditorPane}
                  />
                </Allotment.Pane>

                <Allotment.Pane visible={!isEditorMaximized} minSize={42} preferredSize={'50%'}>
                  <ChatUI
                    messages={messages}
                    setMessages={setMessages}
                    codeContent={codeAnswer}
                    TextContent={textAnswer}
                    answerType={answerType}
                    leetCodeQuestion={leetCodeQuestion}
                    leetCodeAnswer={leetCodeAnswer}
                    questionType='leetcode'
                    additionalPrompt=""
                    isChatMaximized={isChatMaximized}
                    isChatFolded={isChatFolded}
                    setIsChatMaximized={setIsChatMaximized}
                    minimizeChatPane={minimizeChatPane}
                    unminimizeChatPane={unminimizeChatPane}
                  />
                </Allotment.Pane>
              </Allotment>
            )}
          </Allotment>
        </div>

        {/** ================== Submission Modal ================== **/}
        <MUIDialog
          open={openModal}
          onClose={handleCloseModal}
          PaperProps={{
            style: {
              maxWidth: '95%',
              width: '95%',
              height: '95%',
              borderRadius: '12px'
            },
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              zIndex: 2,
            }}
          >
            <CloseIcon style={{ fontSize: '20px' }} />
          </IconButton>
          <DialogContent style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px', paddingBottom: '0' }}>
            <div style={{ flex: 1, paddingRight: '0px', overflowY: 'hidden', borderRight: '1px solid #ccc' }}>
              <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '5px',
                }}>
                  <Typography variant="h6" style={{ marginBottom: '0', fontWeight: 'bold' }}>
                    Submission
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ borderLeft: '1px solid #ccc', height: '24px', marginRight: '8px' }}></div>
                    <Tooltip title={copied ? "Copied" : "Copy Submission"}>
                      <IconCopy onClick={handleCopy} className="cursor-pointer w-5 h-5" />
                    </Tooltip>
                  </div>
                </div>
                <hr />
              </div>
              {answerType === 'Code' ? (
                <Editor
                  language={selectedLanguage}
                  theme="light"
                  value={codeAnswer || ''}
                  options={{
                    minimap: { enabled: false },
                    selectOnLineNumbers: true,
                    automaticLayout: true,
                    scrollBeyondLastLine: false,
                    padding: { top: 15, bottom: 55 },
                    readOnly: true
                  }}
                />
              ) : (
                <div style={{ padding: '15px', paddingBottom: '55px', overflowY: 'auto', height: '100%' }}>
                  <RenderHTMLContent content={textAnswer || ''} />
                </div>
              )}
            </div>

            <div style={{ flex: 1, paddingLeft: '8px', overflowY: 'hidden', position: 'relative' }}>
              <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '5px',
                }}>
                  <Typography variant="h6" style={{ marginBottom: '0', fontWeight: 'bold' }}>
                    Evaluation
                  </Typography>
                  <span
                    className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-1"
                  >
                    {getModelTitle(modelName)}
                  </span>
                </div>
              </div>
              <hr />
              <div style={{ overflowY: 'auto', maxHeight: 'calc(100% - 50px)', paddingTop: '0px' }}>
                <Typography className="ai-message-2" variant="body1">
                  <div className="ai-message-content-2" style={{ marginLeft: '5px' }}>
                    <MarkdownViewer markdownContent={aiEvalResult || ''} />
                  </div>
                </Typography>
              </div>
            </div>
          </DialogContent>
        </MUIDialog>

        {/** ================== Max Submission Dialog ================== **/}
        <Transition show={maxSubmissionDialogOpen}>
          <Dialog className="relative z-10" onClose={() => setMaxSubmissionDialogOpen(false)}>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Maximum Submissions Reached
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              You have reached the maximum of 10 submissions for this question. Please delete a submission if you want to submit a new solution.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/** ================== Usage Limit Dialog ================== **/}
        <Transition show={limitDialogOpen}>
          <Dialog
            className="relative z-10"
            onClose={() => setLimitDialogOpen(false)}
          >
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Model Usage Limit Exceeded
                          </DialogTitle>
                          <div className="mt-2 mb-2">
                            <p className="text-sm text-gray-500">
                              You have reached your API request limit for the <strong>{exceededModel}</strong> model.
                              <br />
                              Current usage: <strong>{currentUsage}</strong> requests out of a limit of <strong>{modelLimit}</strong> for this month.
                              <br />
                              Please upgrade your plan or wait until the next billing cycle to continue using this model.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        onClick={() => {
                          setLimitDialogOpen(false);
                          navigate('/upgrade');
                        }}
                        className="inline-flex justify-center rounded-md bg-green-600 hover:bg-green-700
                             px-4 py-2 text-sm font-semibold text-white shadow-sm
                             focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
                             focus-visible:outline-blue-600"
                      >
                        Upgrade Now
                      </button>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/** ================== Generic Error Dialog ================== **/}
        <Transition show={errorDialogOpen}>
          <Dialog
            className="relative z-10"
            onClose={() => setErrorDialogOpen(false)}
          >
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="absolute top-0 right-0 pt-4 pr-4">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => setErrorDialogOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <IconX className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            {errorDialogTitle || 'Error'}
                          </DialogTitle>
                          <div className="mt-2 mb-2">
                            <p className="text-sm text-gray-500">
                              {errorMessage}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        onClick={() => {
                          setErrorDialogOpen(false);
                          navigate('/upgrade');
                        }}
                        className="inline-flex justify-center rounded-md bg-green-600 hover:bg-green-700 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Upgrade Now
                      </button>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/** ================== Question List Slide-Out ================== **/}
        <Transition.Root show={questionListOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setQuestionListOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 left-0 flex w-full">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel
                      className="
                        pointer-events-auto
                        w-screen max-w-lg
                        transform transition ease-in-out duration-500
                        sm:duration-700
                        bg-white
                        rounded-r-lg
                        shadow-xl
                        flex flex-col
                      "
                    >
                      {/* Header */}
                      <div className="bg-white px-4 sm:px-6 py-4 border-b border-gray-200">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-semibold text-gray-800">
                            <button
                              onClick={() => {
                                navigate('/questions');
                                setQuestionListOpen(false);
                              }}
                              className="transition-colors duration-200 text-indigo-600 hover:text-indigo-800"
                            >
                              Question List ({originalData.length})
                            </button>
                          </Dialog.Title>
                          <button
                            type="button"
                            className="ml-3 text-gray-400 hover:text-gray-600 focus:outline-none"
                            onClick={() => setQuestionListOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                      {/* Scrollable list area */}
                      <div className="flex-1 overflow-y-auto bg-gray-50">
                        <ul className="divide-y divide-gray-200">
                          {originalData.map((question, index) => {
                            const isCurrent = question.qRef === questionId;
                            return (
                              <li key={question.qRef}>
                                <button
                                  onClick={() => {
                                    navigate(`/question/${question.qRef}`);
                                    setQuestionListOpen(false);
                                  }}
                                  className={[
                                    'block w-full text-left text-gray-700 text-sm px-4 py-3 transition-colors duration-200',
                                    isCurrent
                                      ? 'bg-indigo-50 hover:bg-indigo-100'
                                      : 'hover:bg-indigo-50',
                                  ].join(' ')}
                                >
                                  <div className="flex items-center justify-between">
                                    <span className="font-medium truncate">
                                      {`${index + 1}. ${question.title || `Question ${index + 1}`}`}
                                    </span>
                                  </div>
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
    </>
  );
};

export default Question;
